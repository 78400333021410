.light-form {
    .single-row-input {
        display: flex;
        align-items: center;
        justify-content: space-between;
    }
    .light-input-container {
        margin-bottom: $md;
        .light-input-error {
            color: $color-red;
            font-size: $xs-font-size;
            position: absolute;
            margin-left: $sm;
        }
        .light-label {
            text-transform: uppercase;
            font-weight: bold;
            color: $color-blue;
            margin-bottom: $sm;
        }
        .light-input {
            background-color: $color-white;
            border-width: 1px;
            height: 30px;
            width: 100%;
            border-radius: 4px;
            padding: $sm;
            font-family: $font-lato;
            color: $color-blue;
            border: 1px solid $color-blue;
            &:focus {
                outline: none;
            }
            &:disabled {
                cursor: not-allowed;
                background-color: #f5f5f5;
            }
        }
        .light-textarea {
            width: 100%;
            resize: none;
            border: 1px $color-blue solid;
            border-radius: 4px;
            padding: $sm;
            font-family: $font-lato;
            color: $color-blue;
            &:disabled {
                cursor: not-allowed;
                background-color: #f5f5f5;
            }
        }
        .light-textarea:focus {
            outline: none;
        }
        .light-select {
            width: 100%;
            // &.ant-select-open .ant-select-selector {
            //     box-shadow: none !important;
            // }
            .ant-select-selector {
                border: 1px $color-blue solid !important;
                box-shadow: none !important;
                color: $color-blue;
                font-family: $font-lato;
            }
            .ant-select-arrow-icon {
                color: $color-blue;
            }
        }

        .ant-picker {
            border: 1px $color-blue solid !important;
            &:hover {
                box-shadow: unset;
                outline: unset;
                border: 1px $color-blue solid !important;
            }
            &.ant-picker-outlined {
                &:focus, &:focus-within {
                    box-shadow: unset;
                    outline: unset !important;
                    border: 1px $color-blue solid !important;
                }
            }
            .ant-picker-active-bar {
                background: $color-green;
            }
        }
    }
    .light-form-buttons {
        display: flex;
        justify-content: flex-end;
    }
    .ant-checkbox-checked .ant-checkbox-inner {
        background: $color-green;
        border-color: $color-green;
    }
}

//SELECT OPTIONS. SEPARATED BECAUSE OPTIONS ARE OUTSIDE
.light-option {
    color: $color-blue !important;
    font-family: $font-lato;
    font-size: $xs-font-size !important;
}