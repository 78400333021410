.calendar-graph-tooltip-container {
    pointer-events: none;
    display: block;
    height: auto;
    width: auto;
    transition: all 300ms;
    position: absolute;
    background-color: white;
    // border: 1px solid white;
    border-radius: 3px;
    // box-shadow: 3px 3px 10px rgba(0, 0, 0, 0.399);
    overflow: hidden;
    color: #343534;
    min-width: 180px;
    .calendar-graph-tooltip-content {
        padding: 10px 15px;
        .calendar-graph-tooltip-title {
            display: flex;
            justify-content: center;
            font-weight: 600;
            padding-bottom: 10px;
        }
        .calendar-graph-tooltip-body {
            display: grid;
            grid-template-columns: auto auto;
            row-gap: 4px;
            column-gap: 15px;
            span:nth-child(even) {
                font-weight: 600;
            }
            // grid-auto-columns: auto auto;
        }
    }

}