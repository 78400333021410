.chart {
  padding: 40px;
  max-width: 1440px;
  .date_month {
    margin-right: 10px;
  }

  h4 {
    text-transform: uppercase;
    font-weight: bold;
  }

  .date-ranges {
    margin-bottom: 10px;
    min-height: 125px;

    &_item {
      position: relative;
      background-color: #a3e4d7;
      border-radius: 5px;
      padding: 3px 5px;
      font-weight: 700;
      margin-bottom: 5px;
      font-size: 0.9rem;
      button {
        position: absolute;
        right: 3px;
        top: 4px;
      }

      -webkit-animation: fadein 1s; /* Safari, Chrome and Opera > 12.1 */
      -moz-animation: fadein 1s; /* Firefox < 16 */
      -ms-animation: fadein 1s; /* Internet Explorer */
      -o-animation: fadein 1s; /* Opera < 12.1 */
      animation: fadein 1s;
    }
  }

  /* Internet Explorer */
  @-ms-keyframes fadein {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }

  /* Opera < 12.1 */
  @-o-keyframes fadein {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }
  .days-out {
    margin-bottom: 10px;
  }

  td div {
    text-align: center;
  }

  .date-range-picker {
    button {
      margin: 5px;
    }
  }

  .columns {
    margin-bottom: 10px;
  }
  .column {
    &_content {
      padding: 0 10px 5px 10px;
      position: relative;
      height: 100%;
      table {
        text-align: center;
        margin: 0 auto;
      }
      &_header {
        padding: 5px 0;
        margin-bottom: 5px;
        border-bottom: 1px solid #aaa;
      }
    }
  }
  .column.with-border {
    padding: 10px;
    border: 1px solid #aaa;
    border-radius: 5px;
    margin: 5px;
  }

  @keyframes fadein {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }

  @-moz-keyframes fadein {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }

  /* Safari, Chrome and Opera > 12.1 */
  @-webkit-keyframes fadein {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }
}
