.page {
  font-family: $font-titilium;
  background-color: $color-blue;
  display: flex;
  flex-direction: column;
  transition: 300ms;
  // width: 100%;
  // height: 100%;

  overflow: auto;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  .divider {
    min-height: 40px;
    width: 100%;
  }

  .header {
    width: 100%;
    display: flex;
    padding: 20px 0;
    justify-content: space-between;
    .title {
      display: flex;
    }
    h2 {
      font-size: 32px;
      line-height: 32px;
      color: $color-white;
      margin: 0 20px 0 0;
    }
    button {
      position: relative;
      text-align: left;
      background-color: $color-green;
      border-color: $color-green;
      &:hover {
        background-color: #807b7b;
        border-color: #807b7b;
      }
      .icon {
        color: $color-white;
        position: absolute;
        top: 2px;
        right: 11.5px;
      }
    }
  }
  .table-wrapper {
    width: 100%;
  }
  .search-field {
    width: 250px;
    display: flex;
    justify-content: flex-end;
    align-items: center;
  }
}
