@keyframes fadein {
    from { opacity: 0 }
    to   { opacity: 1 }
}
.rms-waterfall-chart {
    display: inline-flex;
    position: relative;
    background-color: white;
    animation: fadein 0.2s ease-in-out;
    > div {
        transform: translateX(20px);
    }
    .title {
        color: #1e2147;
        position: absolute;
        width: 100%;
        text-align: center;
        top: 32px;
        z-index: 0;
        white-space: nowrap;
        font-weight: bold;
    }
    .yTitle {
        position: absolute;
        z-index: 11;
        transform: rotate(-90deg);
        font-weight: bold;
        left: -16px;
        top: 50%;
        color: #1e2147;
    }
    g > g > g > rect {
        stroke-width: 0 !important;
    }
    .google-visualization-tooltip {
        border: none;
        padding: 7px 17px;
        box-shadow: 2px 2px 4px 0px rgb(105, 105, 105);

        > p {
            margin: 0;
            font-weight: 500;
        }
    }
}