.main {
    background-color: $color-blue;
    padding-top: 60px;
    scrollbar-width: none;
    height: 100vh;
    width: 100vw;
    overflow: auto;
    min-width: 1100px;
    & > div {
        position: relative;
        height: 100%;
    }
}

.sidenav {
    height: 100%;
    width: 70px;
    position: fixed;
    z-index: 10;
    top: 60px;
    left: 0;
    background-color: #353d57;
    overflow-x: hidden;
    padding-top: 20px;

    a {
        .fas {
            font-size: 30px;
        }
        margin-bottom: 15px;
        text-decoration: none;
        font-size: 13px;
        color: #fff;
        display: flex;
        flex-direction: column;
        align-items: center;
    }
    a:hover {
        color: #f1f1f1;
    }
}

.container {
    width: 100%;
    position: relative;
    display: flex;
    flex-direction: column;
    &.centered {
        .row {
            width: 1100px;
            padding: 0 $md;
            margin: 0 auto;
        }
    }
    .row {
        width: 100%;
        display: flex;
        flex-wrap: wrap;

        .column {
        flex: 1;
        display: flex;
        flex-direction: column;
        }
    }

    .jumbotron {
        height: 300px;
        width: 400px;
        margin: 40px auto;
        background-color: $color-white;
        border-radius: 20px;
        padding: 30px;
        h2 {
        font-size: 50px;
        }
    }
}

.fetching-container {
    position: relative;
    width: 100%;

    & > div {
        -webkit-animation: fadein 300ms; /* Safari, Chrome and Opera > 12.1 */
        -moz-animation: fadein 300ms; /* Firefox < 16 */
        -ms-animation: fadein 300ms; /* Internet Explorer */
        -o-animation: fadein 300ms; /* Opera < 12.1 */
        animation: fadeIn 300ms;
    }
}

.flex-column {
    display: flex;
    flex-direction: column;
}
 
.rms-icon {
    transition: 0.3s;
}
.rms-icon:hover {
    filter: brightness(85%);
}

.rms-popover {
    > p {
        font-family: $font-titilium;
    }
    > div {
        display: flex;
        justify-content: space-evenly;
        .secondary {
        border: 1px solid $color-green;
        }
    }
}

// .ant-select-dropdown-menu {
//     > li {
//         &:hover {
//         color: $color-green;
//         }
//         &.ant-select-dropdown-menu-item.ant-select-dropdown-menu-item-active {
//             background: $color-grey-light;
//             .anticon.anticon-check.ant-select-selected-icon {
//                 > svg {
//                     color: $color-green;
//                 }
//             }
//         }
//         &.ant-select-dropdown-menu-item.ant-select-dropdown-menu-item-selected {
//             background: $color-grey-light;
//             .anticon.anticon-check.ant-select-selected-icon {
//                 > svg {
//                     color: $color-green;
//                 }
//             }
//         }
//     }
// }

// .ant-select-tree-treenode-checkbox-checked {
//     background-color: $color-grey-light;
//     .ant-select-tree-node-content-wrapper.ant-select-tree-node-content-wrapper-normal {
//         background-color: $color-grey-light;
//         &:hover {
//             color: $color-green;
//             background-color: $color-grey-light;
//         }
//     }
//     .ant-select-tree-title {
//         text-transform: uppercase;
//         font-family: $font-titilium;
//         &:hover {
//             color: $color-green;
//             background-color: $color-grey-light;
//         }
//     }
// }

// .ant-select-tree-title {
//     text-transform: uppercase;
//     font-family: $font-titilium;
//     margin-right: 8;
//     &:hover {
//         color: $color-green;
//         background-color: $color-grey-light;
//     }
// }

// .ant-select-tree-checkbox.ant-select-tree-checkbox-indeterminate {
//     .ant-select-tree-checkbox-inner::after {
//         background: #51b68a;
//     }
// }

// .ant-select-tree-checkbox.ant-select-tree-checkbox-checked {
//     .ant-select-tree-checkbox-inner {
//         background: #51b68a;
//         border-color: #51b68a;
//         &:hover {
//             border-color: #51b68a;
//         }
//     }
// }

// .ant-select-tree-treenode-switcher-open {
//     span[title="ALL SEGMENTS"] {
//         font-weight: 600;
//     }
//     span[title="SEGMENT GROUPS"] {
//         font-weight: 600;
//     }
//     span[title="SEGMENTS"] {
//         font-weight: 600;
//     }
//     .ant-select-tree-switcher.ant-select-tree-switcher_open {
//         display: none;
//     }
//     .ant-select-tree-node-content-wrapper.ant-select-tree-node-content-wrapper-open {
//         &:hover {
//             background-color: $color-grey-light;
//         }
//     }
// }

// .ant-select-tree-switcher.ant-select-tree-switcher_close {
//     .anticon.anticon-caret-down.ant-select-switcher-icon {
//         display: none;
//     }
// }

// .ant-select-tree-treenode-switcher-open.ant-select-tree-treenode-checkbox-checked {
//     .ant-select-tree-switcher.ant-select-tree-switcher-noop {
//         display: none;
//     }
//     .ant-select-tree-switcher.ant-select-tree-switcher_close {
//         display: none;
//     }
//     .ant-select-tree-node-content-wrapper.ant-select-tree-node-content-wrapper-open {
//         &:hover {
//             background-color: $color-grey-light;
//         }
//     }
// }

// .ant-select-tree-treenode-switcher-close {
//     span[title="ALL SEGMENTS"] {
//         font-weight: 600;
//     }
//     span[title="SEGMENT GROUPS"] {
//         font-weight: 600;
//     }
//     span[title="SEGMENTS"] {
//         font-weight: 600;
//     }
//     .ant-select-tree-switcher.ant-select-tree-switcher_close {
//         display: none;
//     }
//     .ant-select-tree-node-content-wrapper.ant-select-tree-node-content-wrapper-close {
//         &:hover {
//             background-color: $color-grey-light;
//         }
//     }
//     > span {
//         &:hover {
//             background-color: $color-grey-light;
//         }
//     }
// }

// .ant-select-tree-treenode-selected {
//     background-color: $color-grey-light;
//     .ant-select-tree-node-content-wrapper.ant-select-tree-node-content-wrapper-close.ant-select-tree-node-selected {
//         background-color: $color-grey-light;
//     }
// }

// .ant-select-tree-node-content-wrapper.ant-select-tree-node-content-wrapper-close.ant-select-tree-node-selected {
//     &:hover {
//         background-color: $color-grey-light;
//     }
// }

// .ant-select-tree-treenode-switcher-open.ant-select-tree-treenode-checkbox-indeterminate {
//     .ant-select-tree-node-content-wrapper.ant-select-tree-node-content-wrapper-open {
//         &:hover {
//             background-color: $color-grey-light;
//         }
//     }
// }

// .ant-select-tree-child-tree.ant-select-tree-child-tree-open {
//     .ant-select-tree-switcher.ant-select-tree-switcher-noop {
//         display: none;
//     }
//     .ant-select-tree-node-content-wrapper.ant-select-tree-node-content-wrapper-normal {
//         &:hover {
//             background-color: $color-grey-light;
//         }
//     }
// }

// .ant-select-dropdown-menu-item {
//     text-transform: uppercase;
//     font-family: $font-titilium;
// }

// .ant-calendar {
//     .ant-calendar-month-select {
//         &:hover {
//             color: $color-green;
//         }
//     }
//     .ant-calendar-year-select {
//         &:hover {
//             color: $color-green;
//         }
//     }
//     .ant-calendar-month-panel-decade-select-content {
//         &:hover {
//             color: $color-green;
//         }
//     }
//     .ant-calendar-year-panel-decade-select-content {
//         &:hover {
//             color: $color-green;
//         }
//     }
//     .ant-calendar-month-panel-month {
//         &:hover {
//             background-color: $color-grey-light;
//             color: $color-green;
//         }
//     }
//     .ant-calendar-year-panel-year {
//         &:hover {
//             background-color: $color-grey-light;
//             color: $color-green;
//         }
//     }
//     .ant-calendar-decade-panel-decade {
//         &:hover {
//             background-color: $color-grey-light;
//             color: $color-green;
//         }
//     }
//     .ant-calendar-month-panel-header {
//         > a {
//             &:hover {
//                 background-color: $color-grey-light;
//                 color: $color-green;
//             }
//         }
//     }
//     .ant-calendar-year-panel-header {
//         > a {
//             &:hover {
//                 background-color: $color-grey-light;
//                 color: $color-green;
//             }
//         }
//     }
//     .ant-calendar-decade-panel-header {
//         > a {
//             &:hover {
//                 background-color: $color-grey-light;
//                 color: $color-green;
//             }
//         }
//     }
//     .ant-calendar-month-panel-year-select-content {
//         &:hover {
//             background-color: $color-grey-light;
//             color: $color-green;
//         }
//     }
//     .ant-calendar-year-panel-year-select-content {
//         &:hover {
//             background-color: $color-grey-light;
//             color: $color-green;
//         }
//     }
//     .ant-calendar-month-panel-cell.ant-calendar-month-panel-selected-cell {
//         > a {
//             background-color: $color-green;
//             color: $color-grey-light;
//         }
//     }
//     .ant-calendar-year-panel-cell.ant-calendar-year-panel-selected-cell {
//         > a {
//             background-color: $color-green;
//             color: $color-grey-light;
//         }
//     }
//     .ant-calendar-decade-panel-cell.ant-calendar-decade-panel-selected-cell {
//         > a {
//             background-color: $color-green;
//             color: $color-grey-light;
//         }
//     }
// }

// .ant-calendar-cell {
//     .ant-calendar-date {
//         &:hover {
//             color: $color-green;
//             background-color: $color-grey-light;
//         }
//     }
//     &.ant-calendar-selected-date.ant-calendar-selected-day {
//         .ant-calendar-date {
//             color: $color-grey-light;
//             background-color: $color-green;
//         }
//     }
//     &.ant-calendar-selected-day {
//         .ant-calendar-date {
//             background-color: $color-grey-light;
//         }
//     }
//     &.ant-calendar-today {
//         .ant-calendar-date {
//             border-color: $color-green;
//             color: $color-green;
//         }
//     }
// }

// .ant-calendar-range {
//     .ant-calendar-cell {
//         &.ant-calendar-selected-start-date.ant-calendar-selected-day, &.ant-calendar-selected-end-date.ant-calendar-selected-day {
//             .ant-calendar-date {
//                 &:hover {
//                     color: $color-green;
//                     background-color: $color-grey-light;
//                 }
//                 background: $color-green;
//             }
//         }
    
//         &.ant-calendar-in-range-cell::before {
//             background: #ccedd8
//         }
//     }
// }

// .ant-calendar-today-btn {
//     color: $color-green;
//     &:hover {
//         color: $color-green;
//     }
// }

@media screen and (max-height: 450px) {
    .sidenav {
        padding-top: 15px;
    }
    .sidenav a {
        font-size: 18px;
    }
}