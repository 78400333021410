.aggregate-view-container {
    width: 100%;
    max-width: 1300px;
    margin: 0 auto 50px auto;
    overflow-y: visible;
    position: relative;
    min-height: 350px;
    height: 100%;
    padding: 0 50px 40px 50px;
    .tabs-container {
        margin-bottom: -14px;
    }
    .aggregated-cell {
        position: relative;
        color: $color-charcoal;
        cursor: pointer;
        width: calc((1160px / 6) - 10px);
        z-index: 2;
        background-color: $color-white;
        margin-right: 5px;
        margin-left: 5px;
        animation-name: fadeIn;
        animation-duration: 400ms;
        animation-timing-function: ease-out;
        &.darkened {
            background-color: $color-neutral;
            // border: 1px solid $color-highlight;
        }
        .status-bar {
            height: $sm;
            &.bad {
                background-color: $color-red;
            }
            &.warning {
                background-color: $color-yellow;
            }
            &.good {
                background-color: $color-green;
            }
            &.neutral {
                background-color: $color-grey-dark;
            }
        }
        .header {
            font-family: $font-titilium;
            font-weight: 700;
            font-size: $md-font-size;
            text-transform: uppercase;
            padding: $sm;
            // border-bottom: 1px solid $color-grey;
            text-align: center;
            span {
                font-family: $font-lato;
                font-weight: 400;
                text-transform: none;
                // display: block;
                color: $color-grey-dark;
                &.week-header {
                    font-size: $sm-font-size;
                }
                // &:nth-child(2) {
                //     font-size: $sm-font-size;
                // }
            }
        }

        &:hover {
            z-index: 101;
            -webkit-box-shadow: 0px 2px 3px 1px rgba(0, 0, 0, 0.2);
            -moz-box-shadow: 0px 2px 3px 1px rgba(0, 0, 0, 0.2);
            box-shadow: 0px 2px 3px 1px rgba(0, 0, 0, 0.2);
            -webkit-backface-visibility: hidden;
            -moz-backface-visibility: hidden;
            -ms-backface-visibility: hidden;
            backface-visibility: hidden;
            .aggregated-cell-row .main-data span,
            .aggregated-cell-row .sub-data span {
                -webkit-backface-visibility: hidden;
                -moz-backface-visibility: hidden;
                -ms-backface-visibility: hidden;
                backface-visibility: hidden;
                transform: translate(0, -50%);
            }
            .aggregated-cell-row .main-data,
            .aggregated-cell-row .sub-data {
                -webkit-backface-visibility: hidden;
                -moz-backface-visibility: hidden;
                -ms-backface-visibility: hidden;
                backface-visibility: hidden;
                transform: translate(0);
            }
            .aggregated-cell-row .hidden-label {
                -webkit-backface-visibility: hidden;
                -moz-backface-visibility: hidden;
                -ms-backface-visibility: hidden;
                backface-visibility: hidden;
                transform: translate(0);
            }

            .aggregated-cell-row .hidden-label span {
                -webkit-backface-visibility: hidden;
                -moz-backface-visibility: hidden;
                -ms-backface-visibility: hidden;
                backface-visibility: hidden;
                transform: translate(0, -50%);
                opacity: 1;
            }
        }

        .aggregated-cell-column {
            padding: $sm;
            width: 100%;
            display: flex;
            flex-direction: column;
            flex-wrap: wrap;
            pointer-events: none;
            font-family: $font-titilium;
            font-weight: 700;

            .block {
                display: flex;
                flex-direction: column;
                transition: margin 0.3s;
                margin-bottom: $md;
            }
            .aggregated-cell-row {
                position: relative;
            }
            .main-data {
                font-size: $lg;
                height: 30px;
                //status
                &.green {
                    color: $color-green;
                }
                &.red {
                    color: $color-red;
                }
                &.yellow {
                    color: $color-yellow;
                }
                &.gray {
                    color: $color-grey-dark;
                }
                &.grey {
                    color: $color-grey-dark;
                }
            }
            .sub-data {
                padding-top: $sm;
                font-size: $md-font-size;
                color: $color-charcoal;
                height: $lg;

                width: 100%;
            }

            .main-data,
            .sub-data {
                text-align: right;
                transform: translateX(-50%);
                transition: transform 200ms;
                position: relative;

                span {
                    display: inline-block; // critically must be inline-block
                    transform: translate(50%, -50%);
                    top: 50%;
                    right: 0;
                    transition: transform 200ms;
                    position: absolute;
                    white-space: nowrap;
                }
            }
        }
        .notes-icon {
            position: absolute;
            right: 7px;
            bottom: 7px;
        }
    }

    .hidden-label {
        transform: translateX(50%);
        transition: transform 200ms;
        position: absolute;
        height: 100%;
        width: 100%;
        div {
            position: relative;
            transform: translate(-50%, -50%);
            top: 50%;
            right: 0;
            width: 100%;
            height: 100%;
        }
        span {
            transition: transform 200ms, opacity 200ms;
            opacity: 0;
            //center in div
            position: absolute;
            left: 50%;
            top: 50%;
            transform: translate(-50%, -50%);
            font-size: $xs-font-size;
        }
    }
    .arrow-navigation-container {
        display: flex;
        width: 1200px;
        margin: $sm auto;
        .icon {
            display: flex;
            height: 30px;
            i {
                flex: 1;
                text-align: center;
            }
            div {
                text-align: center;
                flex: 3;
                display: inline-block;
                line-height: 30px;
            }
            &::last-child {
                flex-direction: row-reverse;
            }
        }
    }

    .load-more-container {
        height: 40px;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-top: $lg;
        gap: 10px;
        .load-more-containter-navigator {
            width: 70px;
            display: flex;
            justify-content: center;
            align-items: center;
            cursor: pointer;
            color: $color-white;
            transition: color 0.3s;
            &:hover {
                color: $color-green;
            }
        }
        .load-more-container-text {
            color: $color-green;
        }
        .rms-button {
            color: $color-green;
            background-color: $color-white;
            border-color: $color-white;
        }
    }

    .aggregate-table-container {
        min-height: 350px;
        width: 100%;
        position: relative;
        margin-top: $md;
        table {
            background-color: $color-white;
            width: 100%;
            position: relative;
            border-collapse: collapse;
            thead {
                tr {
                    background-color: $color-white;
                    color: black;
                }
            }
            tr {
                display: flex;
                flex-direction: row;

                td {
                    flex: 1;
                    height: 45px;
                    display: flex;
                    flex-direction: column;
                    color: black;
                    span {
                        margin: auto auto;
                        text-align: center;
                    }
                    .red {
                        color: $color-bad;
                    }
                    .yellow {
                        color: $color-warning;
                    }
                    .green {
                        color: $color-good;
                    }
                    .gray {
                        color: $color-grey-dark;
                    }
                    .grey {
                        color: $color-grey-dark;
                    }
                    &.status-box {
                        display: flex;
                        div {
                            width: 32px;
                            height: 16px;
                            margin: auto auto;
                        }
                        .red {
                            background-color: $color-bad;
                        }
                        .yellow {
                            background-color: $color-warning;
                        }
                        .green {
                            background-color: $color-good;
                        }
                        .gray {
                            background-color: $color-grey-dark;
                        }
                        .grey {
                            background-color: $color-grey-dark;
                        }
                    }
                }
            }
            thead {
                width: 100%;
                z-index: 10;
                background-color: #5e5e5e;
                color: $color-white;
                padding: 5px 0;
                th {
                    flex: 1;
                    text-align: center;
                    padding: 0 10px;
                    margin: auto 0;
                    position: -webkit-sticky;
                    position: sticky;
                    top: 70px;

                    span {
                        color: $color-neutral;
                        font-size: 10px;
                    }
                }
            }
            tbody {
                display: block;
                height: 400px;
                overflow: auto;
            }
            .table-row {
                transition: 0.3s;
                &.active {
                    background-color: rgba(18, 148, 144, 0.3);
                    &:hover {
                        background-color: rgba(20, 202, 196, 0.3);
                    }
                }
                &:hover {
                    background-color: $color-grey;
                }
            }
        }
    }

    .aggregate-table-container,
    .aggregate-graph-container {
        animation-name: fadeIn;
        animation-duration: 300ms;
        animation-timing-function: ease;
        margin-bottom: 50px;
        position: relative;
    }
}