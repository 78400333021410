.navbar {
  display: flex;
  width: 100%;
  background-color: $color-white;
  -webkit-box-shadow: 0px 4px 26px -9px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: 0px 4px 26px -9px rgba(0, 0, 0, 0.75);
  box-shadow: 0px 4px 26px -9px rgba(0, 0, 0, 0.75);
  margin: 0 auto;

  &.sticky-top {
    position: sticky;
    z-index: 1004;
  }
  &.is-fixed-top {
    position: fixed;
    z-index: 1004;
    top: 0;
  }
  .navbar-content-wrapper {
    width: 100%;
    margin: 0 auto;
    padding: 0 50px;
    display: flex;
    height: 60px;
  }
  .nav-items-wrapper {
    width: 100%;
    display: flex;
    flex-direction: row-reverse;
    font-family: $font-titilium;
    .nav-item {
      color: $color-grey-dark;
    }
    button,
    span {
      display: inline-block;
      margin: auto auto;
    }
    & > div {
      display: flex;
      padding: 0 0 0 20px;
    }
  }
  .active {
    color: $color-green;
    font-weight: 700;
  }
  a {
    color: $color-grey-dark;
    &:hover {
      color: $color-green;
    }
  }
  .logo {
    display: flex;
    padding: 22px 0;
    width: 110px;
    img {
      margin: auto 0;
      height: 100%;
      object-fit: contain;
    }
  }
}

.ant-dropdown {
  &-link.property-group-list, 
  &-link.property-list {
    font-size: $base-font-size;
    font-weight: 700;
    text-transform: uppercase;
    cursor: pointer;
  }

  &.property-menu, &.property-group-menu {
    width: 200px;
    
    .property-menu-properties, .property-menu-property-groups {
      .ant-collapse-item {
        .ant-collapse-header {
          display: flex;
          align-items: center;
          height: 40px;
          padding: 0px 16px;
        }
      }
      .ant-collapse-content .ant-collapse-content-box {
        padding: 12px 0;
        height: 200px;
        overflow: scroll;

        p {
          margin: 0;
          padding: 12px 0;
          // max-width: 200px;
          cursor: pointer;
          &.active {
            margin: 0;
            background-color: $color-grey;
            border-right: 5px solid blue;
          }
        }
      }
    }
  }

  &.property-group-menu {
    width: 220px;
  }

  
  &.fixed {
    position: fixed;
  }
}

.ant-collapse.ant-collapse-borderless {
  border-radius: 0;
}

// modification for ant design dropdown
.ant-menu-vertical > .ant-menu-item,
.ant-menu-vertical-left > .ant-menu-item,
.ant-menu-vertical-right > .ant-menu-item,
.ant-menu-inline > .ant-menu-item,
.ant-menu-vertical > .ant-menu-submenu > .ant-menu-submenu-title,
.ant-menu-vertical-left > .ant-menu-submenu > .ant-menu-submenu-title,
.ant-menu-vertical-right > .ant-menu-submenu > .ant-menu-submenu-title,
.ant-menu-inline > .ant-menu-submenu > .ant-menu-submenu-title {
  margin: 0;
  &:hover,
  & > a:hover {
    color: $color-green;
  }
  &:hover {
    background-color: $color-grey-light;
  }
  &.disabled-link,
  &.disabled-link:hover {
    color: $color-grey;
    a {
      color: $color-grey;
    }
  }
}
.ant-menu-vertical li.ant-menu-item,
.ant-menu-vertical-left .ant-menu-item,
.ant-menu-vertical-right .ant-menu-item,
.ant-menu-inline .ant-menu-item,
.ant-menu-vertical .ant-menu-submenu-title,
.ant-menu-vertical-left .ant-menu-submenu-title,
.ant-menu-vertical-right .ant-menu-submenu-title,
.ant-menu-inline .ant-menu-submenu-title .ant-menu-item:not(:last-child) {
  margin-top: 0;
  margin-bottom: 0;
}
.ant-menu-vertical li.ant-menu-item:not(:last-child),
.ant-menu-vertical-left .ant-menu-item:not(:last-child),
.ant-menu-vertical-right .ant-menu-item:not(:last-child),
.ant-menu-inline .ant-menu-item:not(:last-child) {
  margin-bottom: 0;
}

.ant-collapse-icon-position-right > .ant-collapse-item > .ant-collapse-header,
.fixed.ant-dropdown
  .ant-collapse-borderless
  > .ant-collapse-item
  > .ant-collapse-content
  p {
  transition: all 200ms ease;
  &:hover {
    background-color: $color-grey-light;
    color: $color-green;
  }
}
