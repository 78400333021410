.properties {
    .properties-grid {
        .data-grid {
            div {
                justify-content: flex-start;
                &.data-grid-cell {
                    border-bottom: 1px solid rgb(255, 255, 255);
                    .data-grid-custom-action {
                        width: 100%;
                        &:nth-of-type(1) {
                            justify-content: flex-end;
                        }
                        &:nth-of-type(2) {
                            justify-content: center;
                        }
                        &:nth-of-type(3) {
                            justify-content: flex-start;
                        }
                    }
                }
                &.data-grid-header {
                    font-weight: bold;
                    .clickable-head {
                        div {
                            transition: 0.3s;
                            cursor: pointer;
                            .sort-icon > i {
                                transition: 0.3s;
                            }
                            &:hover {
                                color: rgb(81, 182, 138);
                                .sort-icon > i {
                                color: rgb(81, 182, 138) !important;
                                }
                            }
                            .sort-icon {
                                display: block;
                                margin-left: 10px;
                            }
                        }
                    }
                }
            }
            .data-grid-footer {
                border-bottom: unset;
            }
        }
        .active-properties-panel .inactive-properties-panel {
            &:nth-of-type(5n) {
                justify-content: center;
            }
        }
        .active-groups-panel .inactive-groups-panel {
            &:nth-of-type(4n) {
                justify-content: center;
            }
        }
        .table-footer {
            display: flex;
            align-items: center;
            justify-content: flex-end;
            i {
                width: 30px;
            }
        }
    }

    .inactive-header {
        color: $color-red !important;
    }

    .disabled-action {
        path {
            fill: #868686
        }
    }
}