.forecast-weights {
    width: 1200px;
    margin: 0 auto;
    .fcw-row {
        display: inline-flex;
        width: 100%;
        .fcw-column {
            padding: 0 16px 0 0;
            width: auto;
            &:last-child {
                padding: 0;
            }
            .forms-section {
                .forms-field {
                    .field__label {
                        text-transform: none;
                        font-weight: normal;
                        span {
                            font-size: 14px;
                        }
                    }
                    .field__input {
                        width: 52px;
                        max-width: 60px;
                    }
                }
            }
        } 
    }
}