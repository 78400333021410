.admin-integration-partners {
    .data-grid {
        div {
            justify-content: flex-start;
            &:nth-of-type(4n) {
                justify-content: center;
            }
        }
    }
}

.form-modal-field-status {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 90px;
    .light-label {
        margin-bottom: unset !important;
    }
}

.admin-console-channel-modal-grid {
    font-size: 13px;
    margin-top: 10px;
    .grid-header {
        font-size: 14px;
        font-weight: bold;
        color: #1e2147;
    }
    .data-grid {
        margin-top: 8px;
        div {
            justify-content: flex-start;
        }
        .data-grid-header {
            font-weight: bold;
            font-size: 13px;
            color: #1e2147;
            border-top: 1px solid #1e2147 !important;
            border-bottom: 1px solid #1e2147 !important;
        }
        .data-grid-cell {
            padding-right: 4px;
            &.error {
                input {
                    border: 2px solid #b50c04;
                    border-width: 2px;
                }
                .ant-select-selector {
                    border: 2px solid #b50c04 !important;
                }
            }
        }
        .display-field {
            background: #fff;
            border-width: 1px;
            height: 30px;
            width: 100%;
            border-radius: 4px;
            padding: 8px;
            font-family: "Lato", sans-serif;
            color: #1e2147;
        }
        input {
            background: #fff;
            border-width: 1px;
            height: 30px;
            width: 100%;
            border-radius: 4px;
            padding: 8px;
            font-family: "Lato", sans-serif;
            color: #1e2147;
            outline: none;
        }
        .ant-select-selector {
            border: 1px #1e2147 solid !important;
            box-shadow: none !important;
            font-size: 13px;
            color: #1e2147;
            font-family: "Lato", sans-serif;
            width: 100%;
            height: 30px;
        }  
    }
}
