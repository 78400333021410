.admin-user-management {
    .data-grid {
        div {
            justify-content: center;
            &:nth-of-type(4n + 1) {
                justify-content: flex-start;
            }
            &:nth-of-type(4n) {
                gap: 5px
            }
        }
        margin-bottom: 10px;
        .data-grid-header {
            .clickable-head {
                cursor: pointer;
                .sort-icon {
                    display: block;
                    margin-left: 10px;
                }
            }
        }
    }
    .sort-icon {
        display: block;
    }

    .user-form-property-mapping {
        margin-top: 20px;
        width: 100%;
    }
}

.property-list.users-property-mapping {
    .property-list-header {
        display: none;
    }
    .search-bar {
        border: 2px solid #505a7f;
        border-radius: 6px;
        .search-bar-input {
            background-color: white;
        }
    }

    table {
        width: 100%;
        tbody:before {
            content: "";
            display: block;
            height: 10px;
        }
        th {
            text-transform: uppercase;
            border-bottom: 1px solid #505a7f;
            padding-bottom: 5px;
        }
        td, th {
            text-align:start;
            &:nth-of-type(2n) {
                width: 200px;
                text-transform: uppercase;
                text-align: center;
            }
            &:nth-of-type(3n) {
                width: 100px;
                // text-transform: uppercase;
                text-align: center;
            }
            &:nth-of-type(4n) {
                width: 30px;
                text-align: center;
                padding-top: 5px;
            }
        }

        th {
            // &:nth-last-of-type(3n) {
            //     width: 25px;
            // }
            &:nth-of-type(4n) {
                width: 30px;

            }
        }
    }
    .ant-select {
        &:hover, &:focus {
            .ant-select-selector {
                // #4096ff
                border: none;
                box-shadow: none;
            }
        }
        &-selector {
            color: white;
            background-color: transparent;
            border: solid 1px transparent;
            padding: 0 8px;
        }
        &-arrow {
            margin-top: -6px;
            color: white;
        }
        &-focused {
            .ant-select-selector {
                border-color: transparent !important;
                box-shadow: none !important;
            }
        }
        .ant-select-selection {
            font-size: 13px;
            font-family: "Lato", sans-serif;
            background-color: transparent !important;
            border: solid 1px transparent;
            color: transparent;
            border-radius: 4px;
            &:disabled {
                color: #b5b5b5;
                cursor: not-allowed;
            }
            span: {
                margin: unset;
            }
        }
    }
}

.user-form-property-mapping {
    margin-top: 20px;
    width: 100%;
}

.user-form.forms {
    
    .ant-select {

        &:hover, &:focus {
            .ant-select-selector {
                // #4096ff
                border: none;
                box-shadow: none;
            }
        }

        &-selector {
            color: white;
            background-color: #505a7f;
            border: solid 1px #505a7f;
            padding: 0 8px;
        }
        &-arrow {
            margin-top: -6px;
            color: white;
        }
        &-focused {
            .ant-select-selector {
                border-color: #505a7f !important;
                box-shadow: none !important;
            }
        }
        .ant-select-selection {
            font-size: 13px;
            font-family: "Lato", sans-serif;
            background-color: #505a7f !important;
            border: solid 1px #505a7f;
            color: #fff;
            border-radius: 4px;
            &:disabled {
                color: #b5b5b5;
                cursor: not-allowed;
            }
            span: {
                margin: unset;
            }
        }
    }
    
}