.variable-settings-container {
    // Default DataGrid Component styles
    .data-grid {
        .data-grid-cell {
            padding: 4px;
            &.error {
                .display-field {
                    border-color: rgb(138, 84, 84);
                    background-color: rgb(138, 84, 84);
                }
            }
            input {
                width: 100%;
                height: 100%;
                text-align: center;
                font-size: 13px;
                background-color: #475070;
            }
            .display-field {
                display: inline-block;
                line-height: 2;
                text-align: center;
                width: 100%;
                height: 100%;
                border-style: solid;
                border-color: #505a7f;
                background-color: #505a7f;
                font-size: 13px;
                color: rgb(235, 235, 235);
            }
        }
        .data-grid-footer {
            display: none;
        }
        .indicator {
            width: 60px;
            height: 16px;
            &.good {
                background-color: #51b68a;
            }
            &.avg {
                background-color: #808285;
            }
            &.below-avg {
                background-color: #f28f3c;
            }
            &.bad {
                background-color: #ed474b;
            }
        }
    }
    .defaults-section {
        .data-grid {
            div {
                &:nth-of-type(4n+1) {
                    justify-content: flex-start;
                }
            }
        }
    }
    // Pickup and Pace Section styles
    .pickup-section, .pace-section {
        .data-grid {
            div {
                &:nth-of-type(8n+1) {
                    justify-content: flex-start;
                }
            }
        }
    }
    // Price Section styles
    .price-section {
        .threshold-grid {
            .data-grid {
                div {
                    &:nth-of-type(5n+1) {
                        justify-content: flex-start;
                    }
                }
            }
        } 
        .avg-days-grid {
            margin-top: 30px;
            width: 560px;
            .data-grid {
                div {
                    &:nth-of-type(2n+1) {
                        justify-content: flex-start;
                    }
                }
            }
        }
    }
    // Finals Section styles
    .finals-section {
        .data-grid {
            div {
                &:nth-of-type(6n+1) {
                    justify-content: flex-start;
                }
            }
        }
    }
    // Pricing Recommendation Section styles
    .pricing-recommendation-section {
        .data-grid {
            .data-grid-header {
                svg {
                    height: 23px;
                    width: 23px;
                }
            }
        }
    }

    input {
        height: 100%;
        border-style: solid;
        border-color: $color-blue-light;
        background-color: $color-blue-light;
        &:focus {
            outline: none;
        }
    }
}