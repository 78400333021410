.forecast-weights {
    width: 1227px;
    margin: 0 auto;
    .fcw-row {
        display: inline-flex;
        width: 100%;
        .fcw-column {
            padding: 0 16px 0 0;
            width: auto;
            &:last-child {
                padding: 0;
            }
            .forms-section {
                .forms-field {
                    .field__label {
                        text-transform: none;
                        font-weight: normal;
                        span {
                            font-size: 14px;
                        }
                    }
                    .field__input {
                        width: 52px;
                        max-width: 60px;
                    }
                }
            }
        } 
    }

    .fcw-section {
        .fcw-section-header {
            margin-top: 20px;
            font-size: 1.37em;
            padding-bottom: 8px;
            margin-bottom: 8px;
            color: #51b68a;
        }
        .fcw-section-body {
            padding-left: 20px;
        }
        .fcw-days-out-input {
            width: 237px;
            .field__label {
                text-transform: none;
                font-weight: normal;
            }

            .field__input {
                max-width: 60px;
            }
            // font-size: 13px;
            // font-family: "Lato", sans-serif;
            // background-color: #505a7f;
            // border: solid 1px #505a7f;
            // min-width: 40px;
            // width: 60%;
            // max-width: 60px;
            // color: #fff;
            // border-radius: 4px;
            // height: 32px;
            // padding: 8px;
        }
    }
    


}