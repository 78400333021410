.ant-select-tree li {
    margin: "8px 20px 8px 8px";
}

.ant-select-tree li span.ant-select-tree-switcher {
    position: "absolute";
    right: 0;
}

.rev-custom-filters {
    margin: 0;
    padding: 0;
    list-style-type: none;
    font-family: "Lato";
}

.rev-custom-filters span:hover {
    color: #51b68a;
    cursor: pointer;
    background-color: rgba(0, 0, 0, 0.1);
}

.rev-image-icon .tooltiptext {
    visibility: hidden;
    top: 105%;
    left: 50%;
    margin-left: 40px;
    background-color:$color-grey-light;
    color: $color-blue;
    text-align: center;
    border-radius: 4px;
    padding: 2px 8px;
    position: absolute;
    z-index: 1;
    font-family: 'Lato';
}

.rev-image-icon img:hover {
    cursor: pointer;
    -webkit-filter: invert(100%) hue-rotate(250deg);
    filter: invert(100%) hue-rotate(250deg);
    + span {
        visibility: visible;
    }
}


.calendar {
    display: flex;
    flex-direction: column;
    height: 100%;
    flex: 1;
    &__settings {
        display: flex;
        width: 100%;

        .field {
            padding: 10px;
            flex: 1;
        }
        p {
            margin-bottom: 5px;
        }

        & .sentence {
            margin: auto 0;
            font-size: $base-font-size;
            color: $color-black;

            > span {
                display: flex;
                align-items: center;
                // margin-bottom: 1px;
                .rms-select-filter {
                    position: relative;
                    top: 1px;
                    margin-left: 8px;
                    // margin-bottom: 1px;
                }
                // .ant-select {
                //     font-weight: bold;
                //     text-transform: uppercase;
                // }
                // i {
                //     display: none;
                // }
                // .anticon-caret-down {
                //     display: inline;
                //     font-size: $sm-font-size;
                //     color: $color-black;
                //     bottom: 10px;
                //     position: absolute;
                //     right: $xs;
                // }

                // .ant-select {
                //     padding: 0;
                //     height: 100%;
                // }

                // .ant-select-selection__rendered {
                //     cursor: pointer;
                //     height: 100%;
                // }

                // .ant-select-selection {
                //     outline: none;
                //     border: none;
                //     border-radius: 0px;
                //     background-color: inherit;
                //     color: $color-green;
                //     font-size: 16px;
                //     padding: 0;
                //     min-width: 75px;
                //     text-align: center;
                //     box-shadow: none;
                //     &--single .ant-select-selection__rendered {
                //         margin: 0;
                //     }
                //     &-selected-value {
                //         width: 100%;
                //         padding-right: 20px;
                //         z-index: 1;
                //         position: relative;
                //     }
                // }
                // .rms-select-filter:first-child {
                //     min-width: 120px;
                //     text-align: center;
                //     top: 0;
                //     .anticon-caret-down {
                //         bottom: 8px;
                //     }
                // }
                // .rms-select-filter:last-child {
                //     .ant-select-selection {
                //         min-width: 180px;
                //     }
                // }

                // .check-in-picker {
                //     .ant-calendar-picker-input.ant-input {
                //         width: 75px;
                //         margin-left: 10px;
                //     }
                // }
            }
            .buttons-container {
                margin: auto 0;
                margin-left: auto;
                margin-right: 15px;
                display: flex;
                flex-direction: column;

                div {
                    width: 100%;
                    display: flex;
                }
            }
        }
    }

    .calendar-content {
        padding: 16px 0 70px 0;
    }

    .calendar-view-settings {
        display: flex;
        flex-direction: row;
        width: 100%;
        padding: 16px 20px 0 20px;

        .buttons-container {
            display: flex;
            align-items: center;
            margin-left: $md;
            .rms-button {
                width: initial;
            }
        }
    }

    .calendar-month-input {
        width: 240px;
    }

    .calendar-label {
        font-size: 24px;
        font-weight: 600;
        padding: 0;
        text-align: center;
        color: $color-green;
        position: relative;
        overflow: hidden;
        margin: auto 0;

        .ant-picker {
            border: none;
            background: none;
            font-family: $font-titilium;
            font-size: 14px;
            
            input {
                text-transform: uppercase;
                color: $color-green;
                padding: 0;
                border-radius: 0px;
                cursor: pointer;
                min-width: 80px;
            }
        }
    }

    .calendar-label.month {
        width: 160px;
    }

    .calendar-label.year {
        width: 80px;
    }

    .calendar-header {
        margin: auto;
        padding: 8px 0;
        display: flex;
        justify-content: space-between;
        border-bottom: 1px solid $color-white;
        font-family: $font-titilium;
        font-weight: 600;
        font-size: 24px;
        .month-year-navigation-wrapper {
            display: flex;
            align-items: center;
            justify-content: center;
            & > * {
                margin-right: 10px;
            }

            .date-picker .month-indicator {
                display: flex;
                align-items: center;
                justify-content: center;
            }
        }
    }

    .calendar-navigation {
        color: #c0cdd1;
    }

    .month-indicator > div {
        flex: 1;
    }

    .change-month-button {
        cursor: pointer;
        width: 160px;
    }
    .change-month-button {
        color: $color-white;
        transition: color 0.3s;
        &:hover {
            color: $color-green;
        }
    }

    .month-indicator {
        display: flex;
        margin-right: 10px;
        input {
            border-color: #fff;
            color: #363636;
            font-size: 24px;
            font-weight: 600;
            line-height: 1.125;
            box-shadow: none;
            text-align: center;
            cursor: pointer;
        }

        .react-datepicker-year-header {
            margin-top: 0;
            color: #363636;
            font-weight: bold;
            font-size: 1.2rem;
            height: 2.5em;
        }

        .react-datepicker__month .react-datepicker__month-text {
            display: inline-block;
            color: #363636;
            width: 4rem;
            margin: 2px;
            font-size: 1rem;
        }

        .react-datepicker__month--selected {
            background-color: #ccc;
        }
    }
    .ant-calendar-picker-input {
        text-transform: uppercase;
    }
}

@import "./Aggregate/Aggregate.scss";
@import "./Custom/Custom.scss";
@import "./Daily/Daily.scss";
