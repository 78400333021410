.stay-date-navigator {
    float: right;
    margin: 5px 8px 4px 4px; 
    display: flex; 
    width: 65px; 
    justify-content: space-between; 
    height: "22px";

    &-icon {
        font-size: 14px; 
        color: #434242; 
        align-self: center;
    }
    &-loading {
        align-self: center
    }
    &-prev, &-next {
        display: flex;
        align-self: center;
        font-size: 21px;
        color: #51b68a;
        cursor: pointer;

        &[disabled] {
            color: #818181;
            cursor: not-allowed;
        }
    }
}