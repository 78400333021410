.forms {
    background-color: $color-blue;
    color: $color-white;
    display: flex;

    &-header {
        width: 100%;
        h2 {
            color: $color-white;
            border-bottom: 1px solid $color-white;
            padding-bottom: 5px;
        }
        .rms-button {
            padding: 0;
        }
    }

    .property-info-header {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: flex-end;
    }

    .property-info-tabs {
        ul {
            height: 40px;
            display: flex;
            justify-content: center;
            color: #455a64;
            margin: 0;
            list-style: none;
            font-family: $font-titilium;
            text-transform: uppercase;
            font-weight: 600;
        }
        li {
            padding: 0 16px;
            display: flex;
            flex-direction: column;
            justify-content: center;
            cursor: pointer;
            border-top-right-radius: 10px;
            border-top-left-radius: 10px;
            span {
                display: inline-block;
                text-align: center;
                z-index: 2;
            }
            &.is-active {
                background-color: $color-blue;
                color: $color-white;
            }
        }    
    }

    .container {
        .row {
            width: 1100px;
            .column:last-child {
                padding-left: 25px;
            }
            .column:first-child {
                padding-right: 25px;
            }
            .column:not(:first-child):not(:last-child) {
                padding: 0 25px 0 25px;
            }
            h1, h2 {
                width: 100%;
                margin: 0 auto;
            }
        }
    }

    .forms-section {
        padding: 24px 0 0 0;
        width: 100%;
        h3 {
            color: $color-white;
            border-bottom: 1px solid $color-white;
            padding-bottom: $sm;
            margin-bottom: 8px;
        }
        .custom-header {
            h3 {
                border: 0;
                padding: 0;
                margin: 0 8px 0 0;
            }
            border-bottom: 1px solid $color-white;
            padding-bottom: $sm;
            margin-bottom: $sm;
        }
        .checkbox-wrapper {
            padding: 5px 0;
            span.checkbox-label {
                font-family: $font-titilium;
                font-weight: 600;
                font-size: $sm-font-size;
                text-transform: uppercase;
            }
        }
        .segments-section-header {
            display: flex;
        }
        .new-segment-wrapper {
            display: flex;
            align-items: center;
            margin: 8px 0;
            & > * {
                margin-right: 8px;
            }
        }
        .ant-upload.ant-upload-drag {
            background-color: #4a4c4a;
            height: 88px;
        }

        .forms-drop-message {
            color: #ffffff;
        }

        .forms-subtitle {
            color: #568fe5;
            font-size: $sm-font-size;
        }

        .ant-checkbox-wrapper {
            color: #ffffff;
            width: 100%;
        }

        .ant-checkbox-wrapper + .ant-checkbox-wrapper {
            margin-left: 0px;
        }

        .ant-tabs {
            color: #ffffff;
        }
        .ant-table-thead {
            & > tr > th {
                background-color: $color-blue-light;
                border: none;
                color: #fff;
                padding: 8px 16px;
            }
        }

        .ant-table-header {
            background-color: rgba(0, 0, 0, 0);
        }
        .ant-table-tbody > tr > td {
            background-color: rgba(0, 0, 0, 0);
            border: none;
            padding: 8px 16px;
        }

        // modification due to scrollable content
        .ant-table-fixed-header
        .ant-table-scroll
        .ant-table-header::-webkit-scrollbar {
            border: none;
        }
        .ant-table-fixed-header > .ant-table-content > .ant-table-scroll > .ant-table-body {
            background-color: rgba(0, 0, 0, 0);
            color: $color-white;
        }
        // end of modification due to scrollable content

        // modification for row's hover
        .ant-table-thead > tr.ant-table-row-hover:not(.ant-table-expanded-row) > td,
        .ant-table-tbody > tr.ant-table-row-hover:not(.ant-table-expanded-row) > td,
        .ant-table-thead > tr:hover:not(.ant-table-expanded-row) > td,
        .ant-table-tbody > tr:hover:not(.ant-table-expanded-row) > td {
            background-color: $color-blue-light;
        }
        // end of modification for row's hover
    }

    .forms-table {
        width: 100%;
        border-collapse: collapse;
        thead {
            border-bottom: 1px solid $color-white;
        }
        tbody {
            display: block;
            width: 100%;
        }
        tr {
            display: flex;
            transition: background-color 200ms ease-in-out;
            align-items: center;
            th {
                text-transform: uppercase;
            }
            th,
            td {
                padding: 5px;
                text-align: left;
                flex: 1;
            }

            .table-actions {
                max-width: 60px;
                display: flex;
                flex-direction: row-reverse;
                align-items: center;
            }
        }
    }

    .forms-field {
        margin-bottom: 14px;
        display: flex;
        align-content: center;
        justify-content: space-between;
        &.disabled {
            .field__label {
                color: grey;
            }
            span {
                cursor: not-allowed;
            }
            input {
                cursor: not-allowed;
            }
        }
        .field__label {
            min-height: 35px;
            font-size: $sm-font-size;
            text-transform: uppercase;
            font-family: "Titillium Web", sans-serif;
            font-weight: 600;
            margin-bottom: 0;
            padding-right: 24px;
            display: flex;
            justify-content: space-between;
            align-content: center;
            width: 40%;
            min-width: 160px;
            position: relative;
            color: white !important;
            span.error {
                color: $color-red;
                &::before {
                content: "*";
                position: absolute;
                left: -8px;
                top: -3px;
                font-size: $md-font-size;
                }
            }
        }

        .ant-picker {
            background-color: $color-blue-light;
            border-color: transparent;
            &.ant-picker-disabled:hover { 
                background-color: $color-blue-light !important;
                color: white !important;
            }
            .ant-picker-input {
                width: 100%;
                color: white;
            }
        }
        
        
        .ant-time-picker-icon {
            display: none;
            // margin: auto;
            // .ant-time-picker-clock-icon {
            //     color: white;
            // }
        }

        span {
            margin: auto 0;
            font-size: $sm-font-size;
        }
        ::placeholder {
            color: #ffffff;
        }
        input {
            margin-top: 3px;
        }
    }
    .field__input, .ant-time-picker-input {
        font-size: $sm-font-size;
        font-family: $font-lato;

        background-color: $color-blue-light;
        border: solid 1px $color-blue-light;
        min-width: 40px;
        width: 60%;
        max-width: 248px;
        color: $color-white;
        border-radius: 4px;
        height: 32px;
        padding: 8px;
        &.error {
            border-color: $color-bad;
            color: $color-bad;
        }
        &:focus {
            outline: none;
        }
    }
    .field__rating {
        width: 60%;
        max-width: 248px;
        display: flex;
        align-items: center;
        .ant-rate-star-focused:not(.ant-rate-star-full), .ant-rate-star-zero { 
            .ant-rate-star-second {
                color: rgb(232, 232, 232);
            }
        }
        svg {
            font-size: 18px;
        }
    }
    .action-wrapper {
        display: flex;
        padding: 24px 0;
        flex-direction: row-reverse;
        flex: 1;
        button {
            margin-left: 32px;
            width: 80px;
        }
    }
    .ant-dropdown-trigger {
        width: 60%;
        max-width: 248px;
        display: flex;
        background-color: $color-blue-light;
        justify-content: space-between;
        border: solid 1px $color-blue-light;
        color: $color-white;
        align-items: center;
        margin-top: 3px;
        padding: 8px;

        &:hover {
            background-color: $color-blue-light;
            color: $color-white;
        }
    }

    .ant-dropdown-trigger.ant-btn {
        &:hover,
        &:focus,
        &:active,
        &.active {
            width: 60%;
            display: flex;
            background-color: $color-blue-light;
            justify-content: space-between;
            border: solid 1px $color-blue-light;
            color: $color-white;
            align-items: center;
            margin-top: 3px;
            padding: 8px;
        }
    }
}

span.field-error {
    color: $color-bad;
    font-size: $sm-font-size;
    line-height: 3;
}

.modal-buttons {
    display: flex;
    flex-direction: row-reverse;
    padding-top: 24px;
    button {
        &:first-child {
        margin-left: 8px;
        }
    }
}
.dropdown-add-btn {
    text-align: center;
    border-top: 1px solid #eee;
}

.list-wrapper {
    display: flex;
    flex-direction: column;
    flex: 1;
    overflow: auto;
    max-height: 192px;
    padding-bottom: 8px;
    .list-body {
        display: block;
        .list-item {
            width: 100%;
            padding: 0 8px;
            border-radius: 4px;
            background-color: $color-blue-light;
            margin-bottom: 5px;
            height: 32px;
            display: flex;
            transition: all 0.3s;
            cursor: pointer;
            span {
                margin: auto 0;
            }
            &.active {
                transition: all 0.3s;
                animation-name: fadeIn;
                animation-duration: 300ms;
                animation-timing-function: ease;
                cursor: pointer;
            }
        }
    }
}

.add-segment-option {
    text-transform: uppercase;
    font-weight: bold !important;
    color: $color-blue !important;
    margin-bottom: $sm;
}

.rms-mask {
    background-color: rgba(0, 0, 0, 0.45);
    z-index: 1002;
    height: 100%;
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
}

.delete-segment {
    font-family: $font-lato;
    .segment-span {
        font-weight: bold;
    }
}

.segment-name:hover {
    position: relative;
    .rms-tooltip {
        display: block
    }
}

.rms-tooltip {
    border-radius: 5px;
    background: $color-white;
    color: $color-charcoal;
    position: absolute;
    padding: 6px;
    text-align: center;
    left: 20%;
    top: 100%;
    width: 80%;
    display: none;
    font-size: $xs-font-size;
    font-family: $font-lato;
    &>p {
        margin-bottom: 0;

    }
    &:hover {
        display: none !important;
    }
}


.property-list {
    grid-column: 2 span;
    .form-container {
        display: grid;
        grid-template-columns: minmax(0, 1fr) minmax(0, 1fr);
        justify-content: flex-start;
        align-items: center;
        // grid-gap: 14px;
        .form-field-input {
            width: 100%;
            justify-content: flex-end;
        }
    }
    h3 {
        color: #fff;
        border-bottom: 1px solid #fff;
        padding-bottom: 8px;
        margin-bottom: 25px;
    }
    &.property-list-error {
        h3 {
            color: #de5050;
            &::before {
                color: #de5050;
                content: "*";
                font-size: 18px;
            }
        }
    }
    table {
        margin-top: 20px;
    }
}

// .property-list .ant-select-selection {
//     font-size: 13px;
//     font-family: "Lato", sans-serif;
//     background-color: $color-blue-light !important;
//     border: solid 1px $color-blue-light;
//     color: #fff;
//     border-radius: 4px;
//     &:disabled {
//         color: #b5b5b5;
//         cursor: not-allowed;
//     }
// }


.form-field {
    display: contents;
    padding-top: 20px;
    &:hover {
        border-bottom-color: #fff;
        transition: .3s;
    }
    .form-field-label span {
        position: absolute;
        left: -8px;
        top: -3px;
    }
    .form-field-label-error {
        color: #de5050;
        span::before {
            color: #de5050;
            content: "*"; 
            font-size: 18px;
        }
    }
    .form-field-input-error {
        &.form-field-textbox, .ant-select-selection {
            border: 1px solid #de5050;
        }
    }
    .form-field-error {
        color: #de5050;
        grid-column: 2;
        // margin-top: -14px;
        font-size: 12px;
        min-height: 18px;
    }
}

.rms-dropdown.ant-dropdown {
    .ant-dropdown-menu {
        max-height: 400px;
        overflow: auto;
    }
}