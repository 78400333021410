.custom-avg-dates-modal {
    .data-grid {
        &-header {
            background-color: white;
            border-bottom: 2px solid grey !important;
        }
        div {
            justify-content: flex-start;
            &:nth-of-type(3n) {
                justify-content: center;
            }
        }
    }
}