@import "./theme/ant-design.scss";
@import "./theme/rms-select-filter.scss";
@import "./theme/light-form.scss";

button {
    width: 96px;
    background-color: $color-green;
    border: 1px solid $color-green;
    border-radius: 0 4px 4px 0;
    font-family: $font-titilium;
    font-weight: 600;
    font-size: $sm-font-size;
    transition: all 300ms;
    outline: none;
    cursor: pointer;
}