// COLORS

$color-white: #fff;
$color-black: #231f20;
$color-grey: #d8d8d8;
$color-grey-light: #e9ecee;
$color-grey-dark: #808285;

$color-charcoal: #343534;
$color-charcoal-2: #434242;
$color-charcoal-3: #3a3939;

$color-highlight: #2585f7;
$color-good: #52b78a;
$color-neutral: #d1d3d4;
$color-warning: #f28f3d;
$color-bad: #ed4448;

$color-blue: #1e2147;
$color-blue-light: #505a7f;
$color-green: #51b68a;
$color-yellow: #f28f3c;
$color-red: #ed474b;

$font-titilium: "Titillium Web", sans-serif;
$font-lato: "Lato", sans-serif;

$xs: 4px;
$sm: 8px;
$md: 16px;
$lg: 24px;
$xl: 32px;

$xs-font-size: 12px;
$sm-font-size: 13px;
$base-font-size: 16px;
$md-font-size: 18px;
$lg-font-size: 20px;

// Update Bulma's global variables
//$family-sans-serif: "Libre Franklin", sans-serif;
