// body {
//     font-size: 1rem;
// }

.horizontal-line {
  content: " ";
  width: 100%;
  border-bottom: 1px solid $color-grey;
}

.main {
  font-family: $font-titilium;
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    font-family: $font-titilium;
    font-weight: 600;
    color: black;
  }
}
