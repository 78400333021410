.custom-date-ranges-modal {
    .data-grid {
        &-header {
            background-color: white;
            border-bottom: 2px solid grey !important;
        }
        div {
            justify-content: flex-start;
            &:nth-of-type(3n) {
                justify-content: center;
            }
        }
    }
}

.custom-date-ranges-picker {
    .ant-calendar-next-month-btn {
        padding: unset;
        width: 26px;
        right: 25px;
    }
    .ant-calendar-next-year-btn {
        padding: unset;
        &::before {
            left: 0px;
        }
        &::after {
            left: -4px;
        }
    }
    .ant-calendar-prev-month-btn {
        padding: unset;
        width: 26px;
        left: 25px;
    }
    .ant-calendar-prev-year-btn {
        padding: unset;
        &::before {
            right: 0px;
        }
        &::after {
            right: -4px;
        }
    }
}