.modal-reservations-list {
    width: 100%;
    display: block;
    th {
        text-transform: uppercase;
        cursor: pointer;
        user-select: none;          // supported by Chrome and Opera 
        -webkit-user-select: none;  // Safari
        -khtml-user-select: none;   // Konqueror HTML
        -moz-user-select: none;     // Firefox
        -ms-user-select: none;      // Internet Explorer/Edge
    }
    th, td {
        // text-align: center;
        white-space: nowrap;
        overflow: hidden;
        padding-right: 20px;
        max-width: 300px;
        min-width: 50px;
        height: 40px;
        line-height: 28px;
    }

    
}
.modal-reservation-list-table-header-icons {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    margin-left: 5px;
}
.modal-reservation-list-table-header-popover {
    &:first-child {
        width: 320px
    }


    h4 { 
        font-family: "Titillium Web";
        font-weight: 600;
        color: #8c8c8c;
        margin-bottom: unset;
    }
    .ant-radio-button-wrapper {
        color: #838383;
        font-family: "Titillium Web";
        &.ant-radio-button-wrapper-checked {
            background-color: #fff;
            background: #fff;
            color: #231f20 !important;
            border-color: #d2d2d2;
            box-shadow: unset;
            font-weight: 600;
            &:hover {
                box-shadow: unset;
                background: #f0efef;
                border-color: #d2d2d2;
            }
        }
    }
}