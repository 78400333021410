@import "../../assets/sass/partials/variables";

.manage-property-group {
    overflow-x: auto;

    .property-group-info-header {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: flex-end;
    }

    .property-group-main-tabs {
        ul {
            height: 40px;
            display: flex;
            justify-content: center;
            color: #455a64;
            margin: 0;
            list-style: none;
            font-family: "Titillium Web", sans-serif;
            text-transform: uppercase;
            font-weight: 600;
        }

        li {
            padding: 0 16px;
            display: flex;
            flex-direction: column;
            justify-content: center;
            cursor: pointer;
            border-top-right-radius: 10px;
            border-top-left-radius: 10px;
            &.is-active {
                background-color: #1e2147;
                color: #fff;
            }
        }
    }

    .manage-property-group-tab-contents {
        padding-top: 24px;
        .centered-content {
            width: min-content;
            margin: 0 auto;
            .form-container {
                display: grid;
                grid-template-columns: minmax(0, 1fr) minmax(0, 1fr);
                justify-content: flex-start;
                align-items: center;
                // grid-gap: 14px;
                .form-field-input {
                    width: 100%;
                    justify-content: flex-end;
                }
            }
        }
    }

    .form-layout {
        .form-layout-row {
            display: flex;
            flex-wrap: wrap;
        }
        .form-layout-column {
            flex: 1 1;
            display: flex;
            flex-direction: column;
        }
    }

    .form-layout-row.form-options {
        display: flex;
        padding: 24px 0;
        flex-direction: row-reverse;
        flex: 1;
        margin-right: -28px;
        button {
            margin-left: 32px;
            width: 80px;
        }
    }

    .manage-group-info .form-layout-row,
    .manage-group-admin-controls .form-layout-row {
        .form-container {
            width: 640px;
        }
    }

    .property-list {
        grid-column: 2 span;
        margin-top: 50px;
        h3 {
            color: #fff;
            border-bottom: 1px solid #fff;
            padding-bottom: 8px;
            margin-bottom: 25px;
        }
        &.property-list-error {
            h3 {
                color: #de5050;
                &::before {
                    color: #de5050;
                    content: "*";
                    font-size: 18px;
                }
            }
        }
        table {
            margin-top: 20px;
        }
    }

    .form-field {
        display: contents;
        &:hover {
            border-bottom-color: #fff;
            transition: .3s;
        }
        .form-field-label span {
            position: absolute;
            left: -8px;
            top: -3px;
        }
        .form-field-label-error {
            color: #de5050;
            span::before {
                color: #de5050;
                content: "*"; 
                font-size: 18px;
            }
        }
        .form-field-input-error {
            &.form-field-textbox, .ant-select-selection {
                border: 1px solid #de5050;
            }
        }
        .form-field-error {
            color: #de5050;
            grid-column: 2;
            // margin-top: -14px;
            font-size: 12px;
            min-height: 18px;
        }
    }

    .form-field-label {
        align-items: center;
        min-height: 35px;
        font-size: 13px;
        text-transform: uppercase;
        font-family: "Titillium Web", sans-serif;
        font-weight: 600;
        margin-bottom: 0;
        display: flex;
        align-content: center;
        position: relative;
    }

    .form-field-textbox,
    .form-field-dropdown .ant-select-selector,
    .property-list .ant-select-selector {
        font-size: 13px;
        font-family: "Lato", sans-serif;
        background-color: $color-blue-light !important;
        border: solid 1px $color-blue-light;
        color: #fff;
        border-radius: 4px;
        &:disabled {
            color: #b5b5b5;
            cursor: not-allowed;
        }
    }

    .form-field-textbox,
    .form-field-dropdown {
        min-width: 40px;
        height: 32px;
    }

    .form-field-textbox {
        padding: 11px;
    }
    
    .ant-select-focused .ant-select-selection, 
    .ant-select-selection:focus, 
    .ant-select-selection:active {
        // box-shadow: 0 0 0 2px rgb(24 144 255 / 20%);
        box-shadow: unset;
    }

}