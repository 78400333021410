.view-tabs-container {
    margin-left: auto;
    display: flex;
    flex-direction: column-reverse;
    width: 332px;

    .calendar-tabs {
        ul {
            height: 60px;
            display: flex;
            justify-content: center;
            color: $color-grey;
            list-style: none;
            font-family: $font-titilium;
            text-transform: uppercase;
            font-weight: 600;
        }
        li {
            background-color: $color-charcoal-3;
            flex: 1;
            display: flex;
            flex-direction: column;
            justify-content: center;
            cursor: pointer;
            border-bottom-width: 2px;
            border-left-width: 1px;
            border-right-width: 1px;
            border-top-width: 0;
            border-style: solid;
            border-color: $color-white;
            border-bottom-right-radius: 6px;
            border-bottom-left-radius: 6px;
            padding-bottom: 2px;
            span {
                display: inline-block;
                text-align: center;
                z-index: 2;
            }
            &.is-active {
                background-color: $color-green;
                color: $color-white;
            }
        }
    }
}
