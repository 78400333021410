.rms-pie-chart {
    svg > g >text[text-anchor="start"] {
        text-anchor: middle !important;
        transform: translate(32.5%, -20px)  !important;
        text-transform: uppercase;
    }
    
    path + text[text-anchor="start"]{
        font-family: "Lato", sans-serif;
        text-anchor: start !important;
        transform: initial !important;
    }
}


@keyframes fadein {
    from { opacity: 0 }
    to   { opacity: 1 }
}
@keyframes zoom {
    from { transform: scale(0.8) }
    to   { transform: scale(1) }
}
.rev-pie-chart {
    animation: fadein 0.2s, zoom 0.2s ease-in-out;
    position: relative;
    text {
        white-space: pre-line;
    }
    text[text-anchor="start"] {
        text-anchor: start !important;
    }
    path + text[text-anchor="start"]{
        font-family: "Lato", sans-serif;
        word-break: break-all;
    }
    .title {
        color: #1e2147;
        position: absolute;
        width: 100%;
        text-align: center;
        top: 24px;
        z-index: 0;
        white-space: nowrap;
        font-weight: bold;
        font-size: 16px;
    }
    .google-visualization-tooltip {
        border: none;
        padding: 7px 17px;
        box-shadow: 2px 2px 4px 0px rgb(105, 105, 105);
        
        > p {
            margin: 0;
            font-weight: 500;
        }
    }
    // Stop flickering of tooltip
    div.google-visualization-tooltip { pointer-events: none }
}