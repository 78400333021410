.rms-tabs {
    &.daily-tabs,
    &.view-tabs {
        ul {
            list-style: none;
            display: flex;
            > li:not(:last-child) {
                display: inline;
                margin-right: 4px;
                .tab-button {
                    width: 80px;
                }
                &.is-active {
                    .rms-button {
                        background-color: $color-green;
                        color: $color-white;
                    }
                }
            }
            > li:last-child {
                display: inline;
                .tab-button {
                    width: 80px;
                }
                &.is-active {
                    .rms-button {
                        background-color: $color-green;
                        color: $color-white;
                    }
                }
            }
        }
    }
    &.calendar-tabs {
        ul {
            > li {
                height: 30px;
                position: relative;
                top: 10px;
            }
        }
    }
}
