.table-view {
  table thead tr {
    display: block;
  }

  table tfoot tr {
    display: block;
  }

  table th,
  table td {
    width: 100vw; //fixed width
  }

  table tbody {
    display: block;
    height: 50vh;
    overflow: auto; //set tbody to auto
  }

  .table-tabs {
    position: relative;
    .settings-button {
      position: absolute;
      right: 0;
    }
  }
}
