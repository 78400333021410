.dropdown {
  width: 100%;
  // height: 30px;
  z-index: 10;
  color: $color-grey-dark;
  cursor: pointer;
  // .ant-menu-vertical .ant-menu-item {
  //   background-color: $color-white;
  //   margin-top: 0;
  //   margin-bottom: 0;
  // }
  font-family: $font-titilium;
  font-weight: 400;
  position: relative;
  
  &__menu {
    min-width: 200px;
    background-color: $color-white;
    position: fixed;
    // top: 100%;
    right: 0;
    border: 1px solid $color-white;
    border-radius: 0 0 5px 5px;
    -webkit-box-shadow: 0px 20px 30px -31px rgba(0, 0, 0, 0.75);
    -moz-box-shadow: 0px 20px 30px -31px rgba(0, 0, 0, 0.75);
    box-shadow: 0px 20px 30px -31px rgba(0, 0, 0, 0.75);
    transition: all 0.5s;
    z-index: 0;

    // animation for CSSTransition
    &-enter {
      opacity: 0;
      // transform: translateY(-100px);
    }
    &-enter-active {
      opacity: 1;
      // transform: translateY(0);
      transition: opacity 200ms, transform 200ms;
    }
    &-enter-done {
      position: fixed;
      // top: 70px;
      opacity: 1;
      // transform: translateY(0);
      transition: opacity 200ms, transform 200ms;
    }
    &-exit {
      opacity: 1;
    }
    &-exit-active {
      opacity: 0;
      transition: opacity 300ms, transform 300ms;
    }

    a {
      text-decoration: none;
    }
  }
  
  &__item {
    text-align: center;
    height: 50px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    background-color: $color-white;
    span {
      margin: auto;
      color: $color-grey-dark;
    }
    a {
      margin: auto;
      color: $color-white;
      &.active {
        color: $color-green;
        font-weight: 700;
      }
    }
    &:hover {
      background-color: $color-grey-light;
      color: $color-green;
    }
  }
}
