.rms-tutorial-box {
  font-family: $font-lato;
  // width: 200px;
  position: absolute;
  background: $color-white;
  z-index: 1003;
  color: $color-black;
  border-radius: 5px;
  padding: $md;
  .rms-tutorial-box-footer {
    display: flex;
    justify-content: flex-end;
    margin-top: $md;
  }
  p {
    white-space: pre-wrap;
  }
}

.rms-key-icon {
  height: 50px;
  cursor: pointer;
  animation: zoom 1s infinite;
  position: absolute;
  z-index: 1002;
}

.tutorial-spot {
  height: 12px;
  width: 12px;
  background: $color-yellow;
  cursor: pointer;
  animation: zoom 1s infinite;
  position: absolute;
  z-index: 1002;
  border-radius: 100%;
  min-height: initial !important;
}

.ant-modal-wrap {
  // z-index: 1002 !important;
}

.help {
  position: fixed;
  bottom: 30px;
  left: 30px;
  fill: initial;
  cursor: pointer;
  z-index: 1000;
  font-family: $font-titilium;

  .help-menu {
    color: #808285;
    list-style: none;
    background-color: #fff;
    border-radius: 5px;
    position: absolute;
    bottom: -10px;
    left: calc(100% + 10px);
    white-space: nowrap;
    transition: 0.3s;
    opacity: 0;
    &.enter-done {
      opacity: 1;
    }
    > li {
      cursor: pointer;
      transition: 0.3s;
      padding: 10px;
      &:hover {
        background: #e9ecee;
        color: #51b68a;
      }
      &:first-child {
        border-top-left-radius: 5px;
        border-top-right-radius: 5px;
      }
      &:last-child {
        border-bottom-left-radius: 5px;
        border-bottom-right-radius: 5px;
      }
    }
  }
}

.avatarTutorial,
.searchTutorial,
.otherTutorial,
.filterTutorial,
.dataTypeViewTutorial,
.filter1Tutorial,
.filter2Tutorial,
.filter3Tutorial,
.hotelMenuTutorial,
.currentDateTutorial,
.weeklyCardTutorial,
.monthlyCardTutorial,
.tableTutorial,
.uploadModalTutorial,
.downloadTemplateTutorial,
.sidepanelTutorial,
.chartTutorial,
.pickupTutorial,
.paceTutorial,
.sell_rateTutorial,
.otbTutorial {
  position: relative;
}

@keyframes zoom {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.2);
  }
  100% {
    transform: scale(1);
  }
}

@keyframes wiggle {
  0% {
    transform: rotate(-10deg);
  }
  50% {
    transform: rotate(10deg);
  }
  100% {
    transform: rotate(-10deg);
  }
}

@keyframes zoomWiggle {
  0% {
    transform: rotate(-10deg) scale(1);
  }
  25% {
    transform: rotate(10deg) scale(1.1);
  }
  50% {
    transform: rotate(-10deg) scale(1.2);
  }
  75% {
    trasnform: rotate(10deg) scale(1.1);
  }
  100% {
    transform: rotate(-10deg) scale(1);
  }
}
