.ant-picker, .ant-select, .ant-select-dropdown, .ant-select-tree-list, .ant-dropdown, .ant-collapse {
    font-family: $font-titilium;
}

.ant-picker {
    .ant-picker-input {
        input {
            text-align: center;
        }
    }
}

.ant-picker-dropdown {
    padding-top: 3px;
    button {
        width: unset;
    }
    .ant-picker-panel{
        .ant-picker-header-view {
            button:hover {
                color: $color-green;
            }
            color: rgba(0, 0, 0, .65);
        }
        .ant-picker-body {
            .ant-picker-content {
                thead tr th {
                    color: rgba(0, 0, 0, .65);
                }
                .ant-picker-cell {
                    &.ant-picker-cell-in-range::before {
                        background: #ccedd8;
                    }
                    &.ant-picker-cell-in-view {
                        color: rgba(0, 0, 0, .65);
                        &.ant-picker-cell-disabled {
                            color: rgba(0, 0, 0, 0.25);
                        }
                    }
                    &.ant-picker-cell-hover {
                        color: $color-green !important;
                    }
                    &.ant-picker-cell-today {
                        font-weight: 600;
                        color: $color-green;
                        .ant-picker-cell-inner::before {
                            border: 1px solid $color-green !important;
                        }
                    }
                    &.ant-picker-cell-selected, 
                    &.ant-picker-cell-range-start,
                    &.ant-picker-cell-range-end {
                        &::before {
                            background: unset;
                        }
                        .ant-picker-cell-inner {
                            background: $color-green !important;
                        }
                    }
                }
            }
        }
    }
    .ant-picker-now-btn {
        color: $color-green;
    }
}

// .ant-select {
//     .ant-select-selector {
//         border: 1px solid #d9d9d9 !important;
//         &:hover {
//             border: 1px solid #d9d9d9 !important;
//         }
//     }
//     &.ant-select-focused {
//         .ant-select-selector {
//             border: 1px solid #d9d9d9 !important;
//             box-shadow: unset !important;
//             &:hover {
//                 border: 1px solid #d9d9d9 !important;
//                 box-shadow: unset !important;
//                 outline: none;
//             }
//         }
//     }
// }

.ant-select-dropdown {
    padding: 0px;
    border-radius: 4px !important;
    .ant-select-item {
        color: rgba(0, 0, 0, .65);
        text-transform: uppercase;
        &.ant-select-item-option-selected.ant-select-item-option-active {
            color: rgba(0, 0, 0, .65) !important;
        }
        &.ant-select-item-option-selected {
            color: rgba(0, 0, 0, .65) !important;
            background-color: #e9ecee !important;
        }
        &.ant-select-item-option-active {
            color: $color-green !important;
        }
    }
}


.ant-select-tree {
    // padding: 10px !important;
    .ant-select-tree-list {
        color: rgba(0, 0, 0, .65);
        .ant-select-tree-list-holder {
            max-height: unset !important;
        }
        .ant-select-tree-treenode {
            padding: 5px;
            &:has(.ant-select-tree-checkbox-checked) {
                background-color: $color-grey-light;
            }
            .ant-select-tree-switcher {
                display: none;
            }
            .ant-select-tree-title:hover {
                color: $color-green;
            }
        }
        .ant-select-tree-checkbox {
            &.ant-select-tree-checkbox-indeterminate {
                .ant-select-tree-checkbox-inner::after {
                    background-color: $color-green !important;
                }
            }
            &.ant-select-tree-checkbox-checked {
                .ant-select-tree-checkbox-inner {
                    background-color: $color-green;
                    border-color: $color-green;
                    &:hover {
                        background-color: $color-green !important;
                        border-color: $color-green !important;
                    }
                }
            }
            .ant-select-tree-checkbox-inner {
                border-radius: 2px;
                &:hover {
                    border-color: $color-green !important;
                }
            }
            
        }
    }
}


.ant-dropdown {
    .ant-dropdown-menu {
        padding: unset;
        & > .ant-dropdown-menu-item {
            min-height: 40px;
            padding: 0px 16px;
            color: rgba(0, 0, 0, .65);
        }

        .ant-dropdown-menu-title-content {
            .ant-dropdown-menu-item {
                background-color: unset;
                padding: unset;
                color: rgba(0, 0, 0, .65);
                a {
                    height: 100%;
                    width: 100%;
                    color: $color-grey-dark;
                }
                &:hover {
                    a {
                        color: $color-green;
                    }
                    color: $color-green;
                }
            }
        }
    }
}

.ant-collapse {
    .ant-collapse-item {
        .ant-collapse-content .ant-collapse-content-box p, .ant-collapse-header {
            color: rgba(0, 0, 0, .65);
        }
    }
}

.ant-upload-list-item {
    .ant-upload-list-item-name {
        color: white;
    }
    .ant-upload-icon .anticon {
        color: white !important;
    }
}


.ant-notification-notice-wrapper {
    .ant-notification-notice {
        .ant-notification-notice-content {
            font-family: $font-titilium;
            .ant-notification-notice-message {
                font-weight: 600;
            }
            .ant-notification-notice-with-icon {
                span.ant-notification-notice-icon-warning > svg {
                    fill: $color-yellow;
                }
            }
        }
    }
}