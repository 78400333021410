.slider {
  height: 100%;
  width: 100%;
  position: relative;
  overflow-x: hidden;
  margin-top: $md;
  .slider-items-container {
    margin: 0 auto;
    position: relative;
    display: flex;
    transition: transform ease 500ms;
  }
  .loader {
    position: absolute;
    right: 50px;
    pointer-events: none;
    width: 100px;
    top: 0;
    height: 100%;
    z-index: 1;
  }
}

.slider-container {
  position: relative;
}

.slider-arrow {
  position: absolute;
  z-index: 10;
  top: 50%;
  &--left {
    left: -50px;
    // background: rgb(177, 177, 177);
    // background: linear-gradient(
    //   90deg,
    //   rgba(177, 177, 177, 0.3491771708683473) 0%,
    //   rgba(255, 255, 255, 0) 100%,
    //   rgba(244, 244, 255, 1) 100%
    // );
  }
  &--right {
    right: -50px;
    // background: rgb(255, 255, 255);
    // background: linear-gradient(
    //   90deg,
    //   rgba(255, 255, 255, 0) 0%,
    //   rgba(177, 177, 177, 0.3491771708683473) 100%,
    //   rgba(244, 244, 255, 1) 100%
    // );
  }

  .icon__wrapper {
    position: relative;
    cursor: pointer;
    height: 100%;
    width: 50px;
    transition: all ease 500ms;

    i {
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      color: $color-white;
      font-size: 50px;
      transition: all ease 300ms;
    }

    &:hover {
      i {
        color: $color-green;
      }
    }
  }
}
