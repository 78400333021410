*,
*::after,
*::before {
  margin: 0;
  padding: 0;
}

html {
  // this defines what 1rem is
  font-size: 100%; //1rem = 16px
  body {
    background-color: $color-blue;
    color: $color-white;
    overflow-y: hidden;
    font-size: 14px;
  }
}

.background-grey {
  background-color: $color-grey;
}

.hidden {
  display: none;
}
.relative-position-container {
  position: relative;
  // .cell__status {
  //   position: absolute;
  // }
}
.fixed {
  position: fixed;
}

button.ant-btn.ant-btn-link {
  & > span {
    color: $color-white;
  }

  &:hover {
    background-color: $color-blue-light;
  }
}

// Ant design style modifications
div.ant-modal-content {
  padding: 0px !important;
  border-radius: 10px;
  .ant-modal-body {
    padding: 0;
  }
  .ant-modal-close {
    width: initial;
  }

  button.ant-modal-close {
    width: 50px;
  }
}

.bg-white {
  background-color: $color-white;
}

.pointer {
  cursor: pointer;
}
