.data-grid-container {
    .data-grid {
        display: grid;
        justify-content: stretch;
        position: relative;
        div {
            display: flex;
            justify-content: center; 
            align-items: center;
            overflow: hidden;
            &.data-grid-header {
                position: sticky;
                top: 0;
                font-weight: 600;
                border-top: 1px solid white;
                border-bottom: 1px solid white;
            }
            &.data-grid-cell > input {
                min-width: unset;
            }
        }
        .data-grid-footer {
            border-bottom: 1px solid white;
        }
    }

    .data-grid-pagination {
        display: flex;
        justify-content: flex-end;
        gap: 5px;
    }
}