.dashboard {
    background-color: $color-blue;
    display: flex;
    // height: 100vh;
    // min-height: calc(100vh - 70px);
    position: relative;
    font-family: $font-titilium;
    .dashboard-content {
        transition: all 0.3s;
        height: 100%;
        display: flex;
    }

    .dashboard-sidebar-container {
        z-index: 99;
        transition: transform 0.5s;
        right: 0;
        height: 100vh;
        overflow: auto;
        // z-index: 999;
        position: fixed;
    }
    .dashboard-sidebar {
        background-color: $color-grey;
        color: $color-charcoal-2;
        position: relative;
        &__content {
            padding: $lg;

            .date-label {
                font-size: $lg-font-size;
                display: flex;
                color: $color-white;
                font-weight: 700;
                padding-left: $sm;
                padding-right: $sm;
                justify-content: space-between;
                &.red {
                    color: $color-bad;
                }
                &.green {
                    color: $color-good;
                }
                &.yellow {
                    color: $color-warning;
                }
                &.gray {
                    color: $color-grey-dark;
                }
                &.grey {
                    color: $color-grey-dark;
                }
                &.black {
                    color: $color-black;
                }
                .recommendation-wrapper {
                    // margin-left: 5px;
                    display: flex;
                    flex: 1;
                }
                .recommendation-wrapper {
                    padding: 0 $md;
                    display: flex;
                }
            }

            .header {
                font-size: 30px;
                font-weight: 700;
            }
            .chart-container {
                margin: 20px 0;
                animation-name: fadeIn;
                animation-duration: 200ms;
                background-color: $color-white;
            }
            .wrapper {
                min-height: 275px;
                width: 100%;
                position: relative;
            }

            .icon-container-close {
                color: $color-white;
                font-size: 20px;
                cursor: pointer;
                position: absolute;
                right: 20px;
                top: 20px;
                transition: transform 0.3s;
                &:hover {
                transform: scale(1.05);
                }
            }
        }

        .key-value-list {
            width: 100%;
            font-weight: 400;
            animation-name: fadeIn;
            animation-duration: 300ms;
            .set {
                padding: $md;
                margin: 10px 0px;
                background-color: $color-white;
                cursor: pointer;
                &:hover {
                    transition: .3s;
                    -webkit-box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.6);
                    -moz-box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.6);
                    box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.6);
                }
                &__content {
                    line-height: 1rem;
                    width: 100%;
                    display: flex;
                    flex-wrap: nowrap;
                    flex-direction: row;
                    justify-content: space-between;
                    &--main {
                        margin-bottom: $sm;
                        display: flex;
                        justify-content: space-between;
                        div.set-label {
                        margin-top: auto;
                        margin-bottom: auto;
                        display: flex;
                        flex-direction: column;

                        span:first-child {
                            font-size: 14px;
                            line-height: 14px;
                            font-weight: 700;
                            &.value {
                            text-align: right;
                            font-weight: 700;
                            }
                            &.enlarged {
                            font-size: 18px;
                            line-height: 21px;
                            }
                        }
                        span:last-child {
                            font-size: 12px;
                            &.normal {
                            color: $color-black;
                            }
                        }
                        }
                    }
                }
                &.--green {
                    border-left: 10px solid $color-green;
                    .set__content--main .value {
                        color: $color-green;
                    }
                }
                &.--red {
                    border-left: 10px solid $color-red;
                    .set__content--main .value {
                        color: $color-red;
                    }
                }
                &.--yellow {
                    border-left: 10px solid $color-yellow;
                    .set__content--main .value {
                        color: $color-yellow;
                    }
                }
                &.--gray {
                    border-left: 10px solid $color-grey-dark;
                    .set__content--main .value {
                        color: $color-grey-dark;
                    }
                }
                &.--no-color {
                    border-left: 10px solid transparent;
                    background-color: transparent;
                    padding-top: $sm;
                }

                &.--selected {
                    box-shadow: 0 0 2px 2px rgba(0, 0, 0, 0.5);
                }

                span {
                    font-size: 12px;
                    // line-height: 20px;
                    &.set-label {
                        font-size: 14px;
                        font-weight: 700;
                    }
                    &.label {
                        text-align: left;
                        font-family: $font-lato;
                    }
                    &.value {
                        text-align: right;
                        font-weight: 700;
                    }
                    &.enlarged {
                        font-size: 18px;
                        line-height: 21px;
                    }
                }
            }
        }
        h4, h3 {
            color: $color-white;
        }
    }
}

.sidebar-notes {
    padding: 10px 0;
    margin-bottom: 100px;
    .note-list {
        list-style: none;
        color: white;
        height: 200px;
        overflow: scroll;
        & > li {
            margin-bottom: 10px;
            border: 1px solid #979797;
            padding: 5px 10px;
            border-radius: 5px;
            word-break: break-all;
            .name {
                display: flex;
                justify-content: space-between;
                font-size: 10px;
                opacity: 0.5;
            }
        }
    }
    .actions {
        display: flex;
        justify-content: flex-end;
        margin-top: 10px;
        button {
            &.submit {
                background-color: #5b9af8;
                border-color: #5b9af8;
                color: #fff;
            }
        }
    }
}


.add-notes-container {
    margin-top: 20px;
    display: flex;
    justify-content: flex-end;
    flex-wrap: wrap;
    & > * {
        margin-bottom: 0;
    }

    & > span {
        font-weight: bold;
        display: block;
        text-align: left;
        width: 100%;
    }
    .forms-field {
        margin-bottom: $sm;
        width: 100%;
    }
}

.ant-calendar-header {
    .ant-calendar-prev-year-btn, 
    .ant-calendar-prev-month-btn, 
    .ant-calendar-next-month-btn,
    .ant-calendar-next-year-btn {
        &::before, &::after {
            // border-color: black;
            border-color:black !important;
        }
    }
}