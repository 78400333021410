.admin-message-logs {
    .data-grid {
        div {
            justify-content: flex-start;
            padding-right: 5px;
            &.data-grid-header {
                background-color: #1e2147;
            }
            &.data-grid-footer {
                border-bottom: unset;
            }
        }
        .data-grid-link {
            cursor: pointer;
            text-decoration: underline;
            color: unset;
            &:hover {
                color: #1890ff
            }
        }
    }
    button {
        width: unset;
        border-radius: 4px;
        padding: 4px 15px;
    }
    .load-more-btn {   
        justify-self: center;
        align-self: center;
    }
    // .ant-select {
    //     display: block;
    // }

    .ant-select-open .ant-select-selection-item {
        color: #bfbfbf !important;
    }
    .ant-select-selector {
        color: #51b68a !important;
        border: unset !important;
        background-color: transparent !important;
        .ant-select-selection-placeholder {
            color: #bfbfbf;
        }
    }
    .ant-select-selection--single {
        height: unset;
    }
    .ant-select-selection__rendered {
        overflow: hidden;
    }
    .ant-select-selection__clear, 
    .ant-calendar-picker-clear, 
    .ant-time-picker-clear {
        background-color: transparent;
        color: #51b68a;
        font-size: 14px;
        margin-top: -7px;
    }
    .ant-time-picker-panel-input {
        background-color: transparent;
    }
    .message-logs-filter-panel {
        display: grid;
        grid-template-columns: auto 200px auto 150px auto 140px auto 1fr 3fr;
        grid-auto-rows: 30px;
        width: 100%;
        margin-bottom: 20px;
        align-items: center;
        grid-column-gap: 10px;
        input {
            all: unset;
        }
        .message-logs-filter-end-date, 
        .message-logs-filter-end-time, 
        .message-logs-filter-start-date, 
        .message-logs-filter-start-time {
            border: none;
            display: inline-block;
            background-color: transparent;
            color: #51b68a;
            cursor: pointer;
        }
    }
}

.ant-time-picker-panel-input {
    background-color: transparent;
}

.custom-tabs.custom-tab-message-viewer {
    padding: unset;
    overflow: hidden;
    .custom-tabs-buttons {
        display: flex;
        justify-content: flex-end;
        height: 30px;
        .custom-tab-button {
            width: unset;
            padding: 5px 15px;
            margin-right: 10px;
            color: rgba(0, 0, 0, 0.65);
            font-weight: 600;
            border-top-right-radius: 10px;
            border-top-left-radius: 10px;
            border: none;
            font-size: 14px;
            &.active {
                background-color: #1e2147;
                color: white;
            }
        }
    }
    .custom-tab-container {
        background-color: #1e2147;
        width: 100%;
        color: white;
        font-weight: normal;
        .message-viewer-body-content {
            overflow: auto; 
            padding: 20px; 
            height: 100%;
            width: 100%;
            max-height: 100vh;
            table, th, td {
                border: 1px solid #fff;
                text-align: center;
            }
        }
    }
    .btn-prettify {
        border-radius: 5px 0px 0px 0px;
        width: 50px;
    }
    .btn-copy-body {
        border-radius: 0px 0px 0px 0px;
    }
}
