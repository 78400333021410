.admin-role-management {
    .data-grid {
        div {
            justify-content: center;
            &:nth-of-type(3n + 1) {
                justify-content: flex-start;
            }
            &:nth-of-type(3n) {
                gap: 5px;
            }
        }
        margin-bottom: 10px;
        .data-grid-header {
            .clickable-head {
                cursor: pointer;
                .sort-icon {
                    display: block;
                    margin-left: 10px;
                }
            }
        }
    }
    .sort-icon {
        display: block;
    }
}

.role-form.forms {
    .forms-field {
        margin-bottom: 5px;
        .some-enabled { 
            .switch {
                input:checked + .switch__slider {
                    background-color: #f28f3c;
                }
            }
        }
        
    }
    .container {
        .field {
            &__label {
                width: unset;
            }
            &__input {
                max-width: unset;
                width: 100%;
            }
        }
    }
    .custom-tabs {
        display: flex;
        flex-flow: column;
        .custom-tabs-buttons {
            display: none;
            align-self: center;
            .custom-tab-button {
                text-transform: uppercase;

            }
        }
    }
}