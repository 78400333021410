.search-input {
    position: relative;
    min-width: 200px;
    max-width: 560px;
    transition: all ease 0.3s;
    font-size: $sm-font-size;
    display: flex;
    // justify-content: flex-end;
    align-items: center;
    width: 100%;
    input {
        transition: border 0.3s;
        background-color: transparent;
        border: none;
        border-bottom: 1px solid $color-white;
        width: 100%;
        color: $color-white;
        margin-right: 10px;
        padding: 4px 0px;
        &:focus {
            outline: none;
            border-color: $color-white;
        }
        &::placeholder {
            color: $color-white;
            opacity: 0.3;
            font-size: $sm-font-size;
        }
    }
    .icon-container {
        color: $color-white;
        cursor: pointer;
        i {
            font-size: 17px;
            background: $color-green;
            border-radius: 100%;
            padding: 7px;
            transition: 0.3s;
            &:hover {
                background: darken($color-green, 10%);
            }
        }
        .loadingSpinner .icon-container {
            transform: initial;
            position: initial;
            i {
                font-size: 17px !important;
            }
        }
    }
}
