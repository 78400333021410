.chart-container {
  position: relative;
  // min-height: 150px;
  // height: 100%;
  // background-color: $color-white;
  min-height: 132px;
  margin: 20px 0;
  animation-name: fadeIn;
  animation-duration: 200ms;
  background-color: $color-white;
  cursor: pointer;
  padding: 15px;
  border-radius: 5px;
  transition: box-shadow 0.2s ease-in-out;
  &:hover {
    -webkit-box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.6);
    -moz-box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.6);
    box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.6);
  }
}

.rms-chart-container {
  padding-top: $lg;
}

.legends-wrapper {
  width: 100%;
  display: flex;
  padding: $lg 64px 0 64px;

  .legend-item {
    flex: 1;
    font-size: 12px;
    text-align: center;
    .label {
      display: flex;
      height: 30px;
      align-items: center;
      justify-content: center;
      font-size: $md-font-size;
      span {
        display: inline-block;
        margin-right: $sm;
      }
      span.line {
        height: 3px;
        width: 30px;
        position: relative;
        // &::before {
        //   content: " ";
        //   background-color: inherit;
        //   color: red;
        //   height: 10px;
        //   width: 10px;
        //   border-radius: 100%;
        //   display: block;
        //   font-weight: bold;
        //   position: absolute;
        //   right: 15px;
        //   top: 50%;
        //   transform: translate(50%, -50%);
        // }
      }
      span.bar {
        height: 30px;
        width: 15px;
      }
    }
    p {
      font-family: $font-titilium;
      font-size: $base-font-size;
      font-weight: 700;
    }
  }
}
