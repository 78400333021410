.notes-modal {
    // display: grid;
    // grid-template: 
    //     "header header" min-content
    //     "list form" auto / 1fr min-content;
    // grid-template: min-content auto / 200px;
    height: calc(100vh - 150px);
    max-height: calc(100vh - 130px);
    .table-container {
        overflow: auto;
        .notes-table {
            width: 100%;
            th {
                box-shadow: inset 0 2px 0 #00000099, inset 0 -2px 0 #00000099;
                cursor: pointer;
                text-transform: uppercase;
                height: 40px;
                background-color: white;
                // border-top: 2px solid rgba(0, 0, 0, .6);
                // border-bottom: 2px solid rgba(0, 0, 0, .6);
                text-align: center; 
                user-select: none;          // supported by Chrome and Opera 
                -webkit-user-select: none;  // Safari
                -khtml-user-select: none;   // Konqueror HTML
                -moz-user-select: none;     // Firefox
                -ms-user-select: none;      // Internet Explorer/Edge
                span.sort-active {
                    color: #51b68a;
                }
                &:hover {
                    color: #373737;
                }
                span {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    column-gap: 5px;
                }
            }
            tbody tr {
                border-bottom: .1px solid #00000033;
            }
            tr {
                td {
                    padding: 8px 5px;
                    & > div {
                        display: flex;
                        justify-content: center;
                        align-content: center;
                        text-align: center; 
                        min-height: 1em;
                        max-height: 4.5em;
                        overflow: hidden;
                        &.ellipsis {
                            display: block;
                            text-overflow: ellipsis;
                            white-space: pre;
                            max-width: 450px;
                        }
                    }
                }
            }
        }
        .loading {
            display: block;
            position: absolute;
        }
    }
}