.rms-modal-content {
  padding: $lg;
  display: flex;
  flex-direction: column;
  background-color: $color-white;
  border-radius: 10px;
  font-family: $font-titilium;
  .ant-upload.ant-upload-drag {
    border-radius: 10px;
    // border: 1px solid $color-white;
    border-width: 2px;
    border-color: $color-grey-dark;
    padding: $md 0;
    font-family: $font-lato;
    padding: $md;
    background-color: white;
    .ant-upload-text {
      color: $color-charcoal;
      font-size: $md-font-size;
      span#upload-click-here {
        color: $color-green;
      }
      span#upload-prop-name {
        font-weight: 600;
        text-transform: uppercase;
      }
    }
    .ant-upload-hint {
      font-size: $sm-font-size;
      font-family: $font-lato;
      padding: 0 $lg;
    }
    &:not(.ant-upload-disabled):hover {
      border-width: 2px;
      border-color: $color-grey;
    }
  }
  h3 {
    color: $color-blue;
    font-family: $font-titilium;
    font-weight: 600;
    font-size: $lg;
  }
  .download-template-wrapper {
    display: flex;
    flex-direction: row;
    a {
      font-size: $sm-font-size;
      color: $color-green;
      text-transform: uppercase;
      font-family: $font-titilium;
      font-weight: 600;
      display: flex;
      align-items: center;
      i {
        margin-left: $sm;
      }
    }
  }
  .rms-modal-footer {
    display: flex;
    justify-content: flex-end;
    margin-top: $lg;
    .rms-button.bg-light {
      margin-right: $md;
    }
  }
  .ant-upload-list-item-info span {
    color: white;
    .anticon-loading {
      color: white;
    }
  }
}

.custom-tabs.upload-modal {
  padding: unset;
  overflow: hidden;
  .custom-tabs-buttons {
      display: flex;
      justify-content: flex-end;
      height: 30px;
      .custom-tab-button {
          width: unset;
          padding: 5px 15px;
          margin-right: 10px;
          color: rgba(0, 0, 0, 0.65);
          font-weight: 600;
          border-top-right-radius: 10px;
          border-top-left-radius: 10px;
          border: none;
          font-size: 14px;
          &.active {
              background-color: #1e2147;
              color: white;
          }
      }
  }
  .custom-tab-container {
      background-color: #1e2147;
      width: 100%;
      color: white;
      font-weight: normal;
      padding: 20px;
      // border: 4px solid #1e2147;
      .message-viewer-body-content {
          overflow: auto; 
          padding: 20px; 
          height: 100%;
          width: 100%;
          max-height: 100vh;
          table, th, td {
              border: 1px solid #fff;
              text-align: center;
          }
      }
      .ant-upload.ant-upload-drag {
        border-color: #1e2147
      }
  }
  .ant-radio-group {
    .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled) {
      background: $color-green;
      border-color: $color-green;
      color: $color-white;
    }
    
    .ant-radio-button-wrapper-checked.ant-radio-button-wrapper:hover {
      color: $color-white;
    }

    .ant-radio-button-wrapper:hover {
      color: $color-green;
    }

    span {
      text-transform: uppercase;
      font-size: 13px;
      // color: white;
      font-family: inherit;
      font-weight: 600;
    }
  }

  .parser-upload-options {
    display: flex;
    font-family: $font-titilium;
    margin-bottom: 10px;
    .upload-option {
      height: 24px;
      border: none;
      // border-color: $color-green;
      border-radius: 0px 0px 0px 0px;
      text-transform: uppercase;
      font-weight: 600;
      background-color: white;
      color: $color-green;
      width: 58px;
      // margin-right: 10px;
      &:nth-child(1) {
        border-radius: 4px 0px 0px 4px;
        // width: 98px;
      }
      &:nth-last-child(1) {
        border-radius: 0px 4px 4px 0px;
        width: 74px;
      }
      &.upload-option-active {
        background-color: $color-green;
        color: white;
      }
      // .ant-menu-submenu {
      //   :hover, :focus {
      //     border-radius: 0px 4px 4px 0px;
      //   }
      // }
      // .ant-menu-submenu-title {
      //   height: 24px;
      //   line-height: 23px;
      //   width: 98px;
      //   color: $color-green;
      //   i {
      //     color: $color-green;
      //     top: 10px;
      //   }
      // }
    }
  }
}

.upload-option-others .ant-dropdown-menu-item {
  text-transform: uppercase;
  font-weight: 600;
}