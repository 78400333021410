.dropdown-pickup-filter {
    .dropdown-pickup-filter-custom-container {
        padding: 0px 10px 10px 10px;
    }
    .dropdown-pickup-filter-custom-field {
        &.selected {
            color: #1e2147;
            border: 1px solid #51b68a;
        }
        min-width: unset;
        width: 65px;
        background: #fff;
        color: #818181;
        border: 1px solid #818181;
        border-width: 1px;
        border-radius: 4px;
        padding: 3px;
        font-family: "Titillium Web", sans-serif;
    }

    .dropdown-pickup-filter-custom-button {
        &:disabled {
            cursor: not-allowed;
            border: 1px solid #818181;
            background-color: #818181;
            color: #fff;
        }
        &.selected {
            border: 1px solid #51b68a;
            background-color: #51b68a;
        }
        padding: unset;
        height: 27px;
        width: 60px;
        border-radius: 0px 4px 4px 0px;
        border: 1px solid #818181;
        background-color: #818181;
        color: #fff;
        font-weight: 600;
        text-transform: uppercase;
        font-weight: 600;
        font-size: 13px;
        transition: all 300ms;
        outline: none;
        cursor: pointer;
    }
}