.rms-table-container {
  margin-bottom: 20px;
  font-family: $font-titilium;
  .rms-table-height {
    min-height: 328px;
  }
  .rms-table {
    width: 100%;
    tbody {
      vertical-align: sub;
    }
    .rms-table-icon {
      font-size: 16px;
      color: $color-green;
      margin-right: 8px;
      cursor: pointer;
      transition: 0.3s;
      &:hover {
        transform: scale(1.2);
      }
    }
    .clickable-head {
      div {
        transition: 0.3s;
        cursor: pointer;
        .sort-icon > i {
          transition: 0.3s;
        }
        &:hover {
          color: $color-green;
          .sort-icon > i {
            color: $color-green !important;
          }
        }
      }
    }
    tr {
      td,
      th {
        padding: $md 0;
        border-top: 1px solid;
        border-bottom: 1px solid;
        &:last-child {
          text-align: right;
          & > div {
            justify-content: flex-end;
          }
        }
        & > div {
          display: flex;
          align-items: center;
          min-height: 24px;
        }
        .sort-icon {
          margin-left: 10px;
        }
      }
      td span {
        margin: 0 $xs;
      }
    }
  }
  .table-footer {
    display: flex;
    justify-content: flex-end;
    margin-top: 10px;
    align-items: center;
    font-size: $sm-font-size;
    span,
    i {
      margin-left: 5px;
    }
    i {
      color: $color-white;
      cursor: pointer;
      transition: 0.3s;
      &:hover {
        color: $color-green !important;
      }
    }
  }
}
