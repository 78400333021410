.multiple-select-search {
  display: flex;
  padding-top: 32px;

  .input-wrapper {
    //   height: 32px;
    min-height: 34px;
    height: 100%;
    background-color: transparent;
    border-width: 0.8px 0 0.8px 0.8px;
    border-radius: 4px 0 0 4px;
    border-style: solid;
    border-color: #fff;
    background-color: $color-blue-light;
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    position: relative;
    .selected {
      min-width: 80px;
      background: $color-green;
      padding: 4px 8px;
      border-radius: 4px;
      margin: 0 4px 0 4px;
      font-size: $sm-font-size;
      display: flex;
      align-items: center;
      justify-content: space-between;
      span {
        margin-right: 4px;
      }
    }
  }
  input {
    height: 100%;
    border-style: solid;
    border-color: $color-blue-light;
    background-color: $color-blue-light;
    //   width: 100%;
    min-width: 200px;
    flex-grow: 1;
    &:focus {
      outline: none;
      // box-shadow: inset 1px 1px 24px -6px rgba(33, 38, 54, 1);
    }
    &::placeholder {
      /* Chrome, Firefox, Opera, Safari 10.1+ */
      color: $color-white;
      opacity: 0.5; /* Firefox */
    }
  }
  
  button {
    width: 96px;
    background-color: $color-green;
    border: 1px solid $color-green;
    border-radius: 0 4px 4px 0;
    font-family: $font-titilium;
    font-weight: 600;
    font-size: $sm-font-size;
    transition: all 300ms;
    outline: none;
    cursor: pointer;
  }
  
  .search-results__wrapper {
    position: absolute;
    width: 100%;
    top: 32px;
    background-color: $color-white;
    color: $color-charcoal;
    z-index: 100;
    max-height: 320px;
    overflow: auto;
  
    .item {
      padding: 4px 8px;
      display: flex;
      transition: all 200ms ease-in-out;
      cursor: pointer;
      & > div {
        flex: 1;
      }
  
      &:hover {
        background-color: $color-grey-light;
      }
    }
  }
  
}

