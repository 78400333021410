.rms-select-filter {
    /* Date Picker */
    .ant-picker {
        outline: none;
        border: none;
        padding: unset;
        box-shadow: unset;
        // padding: 0 5px;
        input {
            outline: none;
            border: none;
            text-transform: uppercase;
            background-color: inherit;
            color: $color-green;
            font-weight: bold;
            font-size: 16px;
            border-radius: 0px;
            cursor: pointer;
            box-shadow: none;
        }
    }
    /* Select */
    .ant-select {
        // cursor: pointer;
        .ant-select-selector {
            // min-width: 40px;
            // padding: 0 2px 0 5px;
            padding: unset;
            border: none !important;
            outline: none !important;
            box-shadow: none !important;
            border-radius: none;
            background: none;
            .ant-select-selection-item {
                font-size: 16px;
                font-weight: bold;
                text-transform: uppercase;
                color: $color-green;
                background: none;
            }
        }
    }
    /* Tree Select */
    .ant-tree-select {
        .ant-select-selector {
            // min-width: 40px;
            // padding: 0 2px 0 5px;
            padding: unset;
            border: none !important;
            outline: none !important;
            box-shadow: none !important;
            .ant-select-selection-overflow-item-suffix {
                display: none;
            }
            .ant-tree-select-selection-item {
                font-size: 16px;
                font-weight: bold;
                text-transform: uppercase;
                color: $color-green;
                background: none;
            }
        }
    }
    /* Tree Select Dropdown */
}