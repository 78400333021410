.search-bar {
    display: flex;
    &-options-double-span {
        .ant-select-item {
            div {
                display: inline-flex;
                width: 100%;
                span {
                    width: 50%;
                    text-overflow: ellipsis;
                    overflow: hidden;
                }
            }
        }
    }
    .search-bar-input {
        display: flex;
        align-items: center;
        border: 1px solid #fff;
        background-color: $color-blue-light;
        border-radius: 4px 0 0 4px;
        border-right: none;
        width: 100%;
        > span {
            position: absolute;
            z-index: 2;
        }
        .ant-select {
            background: none;
            .ant-select-selector {
                border: none;
                background: inherit;
                border-radius: unset;
                padding-left: 32px;
                .ant-select-selection-item {
                    background-color:  $color-green;
                    color: #fff;
                    border: none;
                }
                .ant-select-selection-placeholder {
                    padding-left: 25px;
                    color: rgba(255, 254, 254, 0.7);
                }
                .ant-select-selection-search {
                    color: white;
                }
            }
            &.ant-select-focused .ant-select-selector {
                border-color: unset !important;
                box-shadow: unset !important;
            }
        }
    }
    button {
        width: 96px;
        background-color: $color-green;
        border: 1px solid $color-green;
        border-radius: 0 4px 4px 0;
        font-family: $font-titilium;
        font-weight: 600;
        font-size: $sm-font-size;
        transition: all 300ms;
        outline: none;
        cursor: pointer;
        &:disabled {
            background-color: grey;
            border-color: #fff;
            cursor: not-allowed;
        }
    }
}
