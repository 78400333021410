.rms-button {
  height: 24px;
  border-radius: 4px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 300ms;
  outline: none;
  text-transform: uppercase;
  font-family: $font-titilium;
  font-weight: 600;
  font-size: $sm-font-size;
  padding: 0 $sm;
  &.primary {
    background-color: $color-green;
    color: white;
    border-color: $color-green;
  }
  &.secondary {
    background-color: $color-white;
    color: $color-green;
    border: $color-white;
  }
  &.bg-dark {
    background-color: transparent;
    border-color: $color-white;
    color: $color-white;
  }
  &.bg-light {
    background-color: transparent;
    border-color: $color-grey-dark;
    color: $color-grey-dark;
  }
  &.link {
    background-color: transparent;
    border-color: transparent;
    color: $color-white;
    min-width: 0;
  }

  &.circle-button {
    background-color: $color-green;
    color: white;
    border-color: $color-green;
    border-radius: 100%;
  }

  &:hover {
    filter: brightness(90%);
  }

  &.secondary:hover {
    background-color: $color-green;
    color: $color-white;
    border-color: $color-green;
    filter: brightness(100%);
  }
  &.disabled {
    background-color: transparent;
    border-color: $color-grey-dark;
    color: $color-grey-dark;
    cursor: not-allowed;
  }

  // more types here
}
