/* The switch - the box around the slider */
.switch-wrapper {
  border: 1px solid white;
  padding: 1px;
  height: 22px;
  border-radius: 18px;
  width: 34px;
}

.switch {
  position: relative;
  display: inline-block;
  width: 30px;
  height: 18px;
  /* Hide default HTML checkbox */
  input {
    opacity: 0;
    width: 0;
    height: 0;
  }
}

/* The slider */
.switch__slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: 0.4s;
  transition: 0.4s;
  &:before {
    position: absolute;
    content: "";
    height: 18px;
    width: 18px;
    left: 0px;
    background-color: white;
    -webkit-transition: 0.4s;
    transition: 0.4s;
  }
}

input:checked + .switch__slider {
  background-color: $color-green;
}

input:focus + .switch__slider {
  box-shadow: 0 0 1px #2196f3;
}

input:checked + .switch__slider:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translate(12px, 0);
}

/* Rounded switch__sliders */
.switch__slider.round {
  border-radius: 18px;
  //   border: 2px solid $color-white;
}

.switch__slider.round:before {
  border-radius: 50%;
}

.rms-switch {
  margin-right: 0;
  display: flex;
  align-items: center;
  & > span {
    margin-right: $sm;
  }
  button {
    display: inline-block;
    margin: auto 0;
  }
  .ant-switch {
    min-width: 0;
    &-checked {
      background-color: $color-green;
      border-color: white;
    }
  }
  &.font-md {
    > span {
      font-size: $sm-font-size;
    }
  }
}
