.daily-view-container {
    width: 100%;
    padding: 0 20px;
    .no-data {
        margin-top: 100px;
        text-align: center;
        color: white;
        font-size: 20px;
    }
    .daily-calendar-container,
    .daily-table-container,
    .daily-graph-container {
        animation-name: fadeIn;
        animation-duration: 300ms;
        animation-timing-function: ease;
        margin-bottom: 50px;
        position: relative;
    }
    .header {
        height: 37px;
        width: 100%;
        color: #eaebec;
        position: relative;
        text-transform: uppercase;
        font-family: $font-titilium;
        justify-content: center;
        font-size: 16px;
        font-weight: 700;
        display: flex;
        flex-direction: column;
        span {
        text-align: center;
        width: 100%;
        }
    }
    .date-of-week,
    .date-grid {
        display: grid;
        grid-template-columns: repeat(7, 1fr);
        position: relative;
        .loading {
            z-index: 1;
            position: absolute;
            width: 100%;
            height: 100%;
            background-color: rgba($color-neutral, 0.15);
        }
    }

    .cell {
        
        &__days {
            position: relative;
            margin-bottom: 5px;
            cursor: pointer;
            background-color: #eaeced;
            text-align: center;
            transition: all 300ms;
            overflow: hidden;
            min-height: 118px;
            &.noBorderRight {
                border-right: 0;
            }
            &.disabled {
                background-color: #565e81;
            }
            &.off-white {
                background-color: $color-neutral;
            }

            margin: 2px;

            &.selectedCell {
                background-color: #ffffff;
            }

            &:hover {
                border-right: 0;
                transform: scale(1.1);
                z-index: 999;
                box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.75);
                border-radius: 3px;
                .cell__data-container {
                    padding: 0px 15px;
                }

                .data {
                    transform: scale(0.91);
                    .main-data {
                        -webkit-backface-visibility: hidden;
                        -moz-backface-visibility: hidden;
                        -ms-backface-visibility: hidden;
                        backface-visibility: hidden;
    
                        text-align: right;
                    }
    
                    .small-data {
                        -webkit-backface-visibility: hidden;
                        -moz-backface-visibility: hidden;
                        -ms-backface-visibility: hidden;
                        backface-visibility: hidden;
                        text-align: right;
                    }
    
                    .sub-data {
                        -webkit-backface-visibility: hidden;
                        -moz-backface-visibility: hidden;
                        -ms-backface-visibility: hidden;
                        backface-visibility: hidden;
                        text-align: right;
                    }
    
                    .hidden-label {
                        -webkit-backface-visibility: hidden;
                        -moz-backface-visibility: hidden;
                        -ms-backface-visibility: hidden;
                        backface-visibility: hidden;
                        transform: translate(-7px);
                    }
    
                    .hidden-label span {
                        -webkit-backface-visibility: hidden;
                        -moz-backface-visibility: hidden;
                        -ms-backface-visibility: hidden;
                        backface-visibility: hidden;
                        transform: translate(0, -50%);
                        opacity: 1;
                    }
                }
                
            }

            .data {
                font-family: $font-titilium;
                font-weight: 700;
                color: $color-charcoal-3;

                .calendar__cell__info {
                    position: absolute;
                    width: 100%;
                    height: 100%;
                    background-color: blue;
                }
                .hidden-label {
                    font-size: 10px;
                    padding-left: 5px;
                    color: $color-charcoal-3;
                    font-weight: 700;
                    font-family: $font-titilium;
                    pointer-events: none;

                    transform: translateX(50%);
                    transition: transform 200ms;
                    position: absolute;
                    height: 100%;
                    width: 100%;
                    div {
                        position: relative;
                        transform: translate(-50%, -50%);
                        top: 50%;
                        right: 0;
                        width: 100%;
                        height: 100%;
                    }
                    span {
                        transition: transform 200ms, opacity 200ms;
                        opacity: 0;
                        //center in div
                        position: absolute;
                        left: 50%;
                        top: 50%;
                        transform: translate(-50%, -50%);
                        font-size: $xs-font-size;
                    }

                }
                .main-data {
                    display: inline-block;
                    font-size: 24px;
                    line-height: 24px;
                    font-weight: 700;
                    &--gray {
                        color: $color-grey-dark;
                    }
                    &--green {
                        color: $color-good;
                    }
                    &--red {
                        color: $color-bad;
                    }
                    &--yellow {
                        color: $color-warning;
                    }
                }
                .sub-data {
                    margin: 8px 0 12px 0;
                    font-size: 14px;
                    line-height: 16px;
                }
                .small-data {
                    font-size: 14px;
                    line-height: 16px;
                    margin: 0 0 4px 0;
                    white-space: pre;
                }
                .small-data:nth-child(2) {
                    margin-top: 16px;
                }

                .recommended-data {
                    height: 18px;
                    font-size: 18px;
                    line-height: 18px;
                }

                .main-data,
                .sub-data,
                .recommended-data,
                .small-data {
                    position: relative;
                    width: 100%;
                }
            }
        }

        &__date {
            font-family: $font-titilium;
            font-size: 14px;
            line-height: 14px;
            font-weight: 700;
            padding: 8px 8px 0 8px;
            text-align: left;
            color: $color-charcoal-3;
            display: flex;
            justify-content: space-between;
        }

        &__status {
            display: block;
            height: 8px;
            position: absolute;
            bottom: 0;
            width: 100%;
            &.--green {
                background-color: $color-green;
            }
            &.--red {
                background-color: $color-red;
            }
            &.--warning {
                background-color: $color-yellow;
            }
            &.--neutral {
                background-color: $color-grey-dark;
            }
            &.--none {
                background-color: transparent;
            }
        }
        h4 {
            font-size: 1.2rem;
            position: absolute;
            display: block;
            left: 50%;
            top: 50%;
            transform: translate(-50%, -50%);
            font-weight: 700;
            color: $color-white;
        }
    }

    .tabs-container {
        display: flex;
        margin-bottom: -14px;
        .view-tabs {
            margin-right: 60px;
        }
    }

    .daily-table-container {
        min-height: 350px;
        width: 100%;
        position: relative;
        margin-top: $md;
        table {
            background-color: $color-white;
            width: 100%;
            position: relative;
            border-collapse: collapse;
            thead {
                tr {
                    background-color: $color-white;
                    color: black;
                }
            }
            tr {
                display: flex;
                flex-direction: row;
        
                td {
                    flex: 1;
                    height: 45px;
                    display: flex;
                    flex-direction: column;
                    color: black;
                    span {
                        margin: auto auto;
                        text-align: center;
                    }
                    .red {
                        color: $color-bad;
                    }
                    .yellow {
                        color: $color-warning;
                    }
                    .green {
                        color: $color-good;
                    }
                    .gray {
                        color: $color-grey-dark;
                    }
                    .grey {
                        color: $color-grey-dark;
                    }
                    &.status-box {
                        display: flex;
                        div {
                            width: 32px;
                            height: 16px;
                            margin: auto auto;
                        }
                        .red {
                            background-color: $color-bad;
                        }
                        .yellow {
                            background-color: $color-warning;
                        }
                        .green {
                            background-color: $color-good;
                        }
                        .gray {
                            background-color: $color-grey-dark;
                        }
                        .grey {
                            background-color: $color-grey-dark;
                        }
                    }
                }
            }
            thead {
                width: 100%;
                z-index: 10;
                background-color: #5e5e5e;
                color: $color-white;
                padding: 5px 0;
                th {
                    flex: 1;
                    text-align: center;
                    padding: 0 10px;
                    margin: auto 0;
                    position: -webkit-sticky;
                    position: sticky;
                    top: 70px;
        
                    span {
                        color: $color-neutral;
                        font-size: 10px;
                    }
                }
            }
            tbody {
                display: block;
                height: 400px;
                overflow: auto;
            }
            .table-row {
                transition: 0.3s;
                &.active {
                    background-color: rgba(18, 148, 144, 0.3);
                    &:hover {
                        background-color: rgba(20, 202, 196, 0.3);
                    }
                }
                &:hover {
                    background-color: $color-grey;
                }
            }
        }
    }
    
}
