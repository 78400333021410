.custom-tabs {
    width: 100%;
    padding: 0 80px;
    .custom-tab-container {
        margin: 0 auto;
    }
    .custom-tab-button {
        border-radius: 0;
        height: 28px;
        width: 155px;
        font-size: 15px;
        background-color: white;
        border-color: white;
        color: #51b68a;
        margin: 2px;
        &.active {
            background-color: #51b68a;
            border-color: #51b68a;
            color: white;
        }
    }
}

.custom-tabs-room {
    width: 100%;
    .custom-tab-container {
        margin: 0 auto;
    }
    .custom-tab-button {
        border-radius: 0;
        height: 28px;
        width: 155px;
        font-size: 15px;
        background-color: white;
        border-color: white;
        color: #51b68a;
        margin: 2px;
        &.active {
            background-color: #51b68a;
            border-color: #51b68a;
            color: white;
        }
    }
}