@keyframes fadein {
    from { opacity: 0 }
    to   { opacity: 1 }
}
.rev-table-chart {
    animation: fadein 0.2s ease-in-out;
    table, th, td {
        border: 1px solid rgba(0, 0, 0, 0.6);
    }
    td, th {
        text-align: center;
        padding: '12px 16px';
        font-family: "Lato", sans-serif;
        color: black;
        font-size: 8;
    }
    tr.rev-table-chart-hover {
        :hover {
            cursor: pointer;
        }
    }
}