.admin-segments {
    .data-grid {
        div {
            justify-content: flex-start;
            &:nth-of-type(5n) {
                justify-content: center;
            }
        }
    }
}

.add-new-segment-group-opt {
    text-decoration: underline;
    font-weight: bold !important;
    text-align: center;
    font-size: 14px;
}