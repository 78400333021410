@keyframes fadein {
    from { opacity: 0 }
    to   { opacity: 1 }
}
.rms-line-chart {
    display: inline-flex;
    position: relative;
    background-color: white;
    animation: fadein 0.2s ease-in-out;
    > div {
        transform: translateX(28px);
    }
    .title {
        color: #1e2147;
        position: absolute;
        width: 100%;
        text-align: center;
        top: 32px;
        z-index: 0;
        white-space: nowrap;
        font-weight: bold;
    }
    .yTitle {
        position: absolute;
        z-index: 11;
        transform: rotate(-90deg);
        font-weight: bold;
        left: -68px;
        top: 50%;
        color: #1e2147;
    }
    .rms-line-chart-lines {
        display: flex;
        justify-content: center;
        position: absolute;
        width: 100%;
        bottom: -20px;
        left: -30px;
        font-size: 12px;
        .legend {
            display: flex;
            align-items: center;
            font-family: $font-lato;
            .colored-line {
                display: inline-block;
                width: 20px;
                height: 3px;
                margin-left: 10px;
                margin-right: 10px;
            }
        }
    }
    .google-visualization-tooltip {
        border: none;
        padding: 7px 17px;
        box-shadow: 2px 2px 4px 0px rgb(105, 105, 105);
        
        > p {
            margin: 0;
            font-weight: 500;
        }
    }
}

