@keyframes moveInRight {
  0% {
    opacity: 0;
    transform: translateX(300px);
  }

  100% {
    opacity: 1;
    transform: translate(0);
  }
}
@keyframes moveInLeft {
  0% {
    opacity: 0;
    transform: translateX(-100px);
  }

  100% {
    opacity: 1;
    transform: translate(0);
  }
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes slideToRight {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(100px);
  }
}

@keyframes growIn {
  0% {
    opacity: 0;
    transform: scale(0.9);
  }
  100% {
    opacity: 1;
    transform: scale(1);
    transition: opacity 300ms, transform 300ms;
  }
}

.grow-in {
  -webkit-animation: growIn 300ms; /* Safari, Chrome and Opera > 12.1 */
  -moz-animation: growIn 300ms; /* Firefox < 16 */
  -ms-animation: growIn 300ms; /* Internet Explorer */
  -o-animation: growIn 300ms; /* Opera < 12.1 */
  animation: growIn 300ms;
}

.fade-in {
  -webkit-animation: fadein 300ms; /* Safari, Chrome and Opera > 12.1 */
  -moz-animation: fadein 300ms; /* Firefox < 16 */
  -ms-animation: fadein 300ms; /* Internet Explorer */
  -o-animation: fadein 300ms; /* Opera < 12.1 */
  animation: fadeIn 300ms;
}

// fade in animation
.fade-enter {
  opacity: 0;
}
.fade-enter-active {
  opacity: 1;
  transition: opacity 300ms linear 150ms;
}
.fade-exit {
  opacity: 1;
}
.fade-exit-active {
  opacity: 0;
  transition: opacity 150ms linear;
}

// Grow in animation
.grow-enter {
  opacity: 0;
  transform: scale(0.9);
}
.grow-enter-active {
  opacity: 1;
  transform: translateX(0);
  transition: opacity 300ms, transform 300ms;
}
.grow-exit {
  opacity: 1;
}
.grow-exit-active {
  opacity: 0;
  transform: scale(0.9);
  transition: opacity 300ms, transform 300ms;
}

// slide in animation
.slide-enter {
  transform: translate(100%);
  opacity: 0;
}
.slide-enter-active {
  opacity: 1;
  transform: translate(0%);
  transition: transform 300ms ease-in-out;
}
.slide-exit {
  opacity: 1;
  transform: translate(0%);
}
.slide-exit-active {
  opacity: 0;
  transform: translate(-100%);
  transition: transform 300ms ease-in-out;
}
