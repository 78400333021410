.login {
  display: flex;
  background-color: #61646d;
  width: 100vw;
  height: 100vh;
  justify-content: center;
  align-items: center;

  .login-container {
    width: 435px;
    border-radius: 8px;
    box-shadow: 0 6px 8px 0 rgba(0, 0, 0, 0.1);
    background-color: #46484e;

    flex-direction: column;
    justify-content: center;
    padding: 48px;

    font-family: Roboto;

    color: #ffffff;

    .logo {
      width: 64px;
      height: 64px;
      border-radius: 8px;
      margin: auto;
      font-size: 12px;
      line-height: 1.33;
      letter-spacing: 0.2px;
      margin-bottom: 12px;
      background-color: #d8d8d8;
    }

    .login-error {
      background-color: #ffffff;
      opacity: 0.6;
      border-radius: 4px;
      padding: 14px;
      margin: 14px 0px;
      color: #494949;
      display: flex;
      font-size: 12px;

      .login-error-icon {
        font-size: 40px;
        color: #f44336;
      }
      .login-error-message {
        margin-left: 10px;
      }
    }

    .login-form {
      margin-top: 20px;
      .login-field {
        height: 50px;
        margin: 14px 0;
      }

      .login-field-error {
        font-size: 12px;
        color: #f44336;
      }

      input {
        background-color: transparent;
        border: none;
        border-bottom: solid 1px #f1f3f5;
        opacity: 0.4;
        width: 100%;
        color: #ffffff;
      }
      ::placeholder {
        color: #ffffff;
      }

      input:focus {
        outline: none;
      }
    }

    .login-secondary {
      .ant-checkbox-wrapper {
        color: #ffffff;
        opacity: 0.87;
      }
      display: flex;
      .login-secondary-item {
        flex: 0.5;
      }
      .login-secondary-item:last-child {
        flex: 0.5;
        text-align: right;
      }
    }

    .login-button {
      margin-top: 30px;
    }

    .login-label-small {
      font-size: 13px;
      text-align: center;
    }
    .login-label-large {
      font-size: 20px;
      line-height: 1.25;
      text-align: center;
    }
  }
}
