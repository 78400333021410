.rms-dashboard-modal {
    min-height: calc(100vh - 116px);
    width: 100%;
    top: 0;
    background: $color-blue;
    position: absolute;
    z-index: 10;
    transform: translateX(-150%);
    transition: transform .3s;
    bottom: 0;
    padding: $md;
    &.active {
        transform: translateX(0);
    }
    &-header {
        h3 {
            color: $color-blue;
            font-size: $lg-font-size;
            text-align: center;
            margin-bottom: 0;
        }
    }
    &-container {
        min-height: 100%;
        width: 100%;
        background: $color-white;
        border-radius: 5px;
        &-body {
            color: $color-black;
            // padding: $xl;
            padding-top: 0;
            overflow-x: hidden;
            .rms-modal-content {
                padding-bottom: $md;
            }
        }
    }
    &-close {
        position: absolute;
        top: 24px;
        right: 24px;
    }
    &-tabs {
        font-family: $font-titilium;
        color: $color-black;
        font-weight: bold;
        text-transform: uppercase;
        display: flex;
        list-style: none;
        padding-left: $md;
        padding-top: $md;
        >li {
            margin: 0 $md;
            cursor: pointer;
            &.active {
                border-bottom: 2px solid $color-green;
                color: $color-green;
                transition: color .3s;
            }
            &:hover{
                color: $color-green;
                transition: color .3s;
            }
        }
    }
}


@keyframes slide {
    from {transform: translateX(-150%);}
    to {transform: translateX(0);}
}