.ope-card {
  border: solid 1px #979797;
  background-color: #ffffff;
  padding: 24px 40px;
  margin: 15px;
  max-width: 1440px;
  &.borderless-top {
    border-top: none;
  }
}
