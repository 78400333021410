.loadingSpinner {
  width: 100%;
  height: 100%;
  // background-color: rgba($color-grey, 0.2);
  z-index: 10;
  .icon-container {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
  }
  &.with-mask {
    position: fixed;
    background-color: rgba($color-grey, 0.2);
  }
}
