@keyframes fadein {
    from { opacity: 0 }
    to   { opacity: 1 }
}
.rms-stacked-chart {
    width: fit-content;
    text-align: center;
    animation: fadein 0.2s ease-in-out;
    &-title {
        font-weight: bold;
        text-transform: uppercase;
        color: #1e2147;
    }
    .google-visualization-tooltip {
        border: none;
        padding: 7px 17px;
        box-shadow: 2px 2px 4px 0px rgb(105, 105, 105);
        text-align: start; 
        > p {
            margin: 0;
            font-weight: 500;
        }
    }
    // Stop flickering of tooltip
    div.google-visualization-tooltip { pointer-events: none }
}