.users {
}

.property-mapping-modal {
  height: 500px;
  overflow: scroll;
  .ant-table-thead > tr > th {
    background-color: $color-green;
    color: $color-white;
  }
}
