.rms-accordion {
  width: 100%;
  .forms-section {
    padding-bottom: 0;
    padding-top: 0;
  }
  .action-wrapper {
    padding-right: 24px;
  }
  .ant-collapse {
    font-family: $font-titilium;
    .ant-collapse-header {
      padding-left: 0;
      padding-right: 0;
      display: flex;
      justify-content: space-between;
      align-items: center;
      i, span {
        font-size: $md-font-size;
        transition: 0.3s;
      }
    }
  }
  .ant-collapse-content {
    color: inherit;
    margin-bottom: 10px;
    .ant-collapse-content-box {
      padding: 0;
    }
  } 
  .ant-collapse {
    background-color: transparent;
    .ant-collapse-item {
      transition: 0.2s;
      border-bottom: 1px solid white !important;
      margin-bottom: 12px;
      &.ant-collapse-item-active {
        border-bottom: 1px solid transparent !important;
        .ant-collapse-header {
          .anticon-caret-right {
            transform: rotate(90deg);
          }
        }
      }
      .ant-collapse-header {
        color: #ffffff; 
        line-height: 22px;
        h3 {
          color: $color-green;
          font-size: $lg-font-size;
          margin: unset;
        }
      }
    }
  }
}